import clsx from "clsx";
import React from "react";
import styles from "./PageCard.module.scss";

export const PageCard: React.FC<React.HTMLAttributes<HTMLDivElement>> = ({
  children,
  className,
  ...rest
}) => {
  return (
    <div className={clsx(styles.container, "bg-white w-95 mt-100", className)} {...rest}>
      {children}
    </div>
  );
};
