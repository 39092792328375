import React from "react";
import clsx from "clsx";
import styles from "./Label.module.scss";

export const Label: React.FunctionComponent<
  React.DetailedHTMLProps<React.LabelHTMLAttributes<HTMLLabelElement>, HTMLLabelElement>
> = ({ children, className, ...rest }) => {
  return (
    <label
      className={clsx(styles.container, "form-check-label m-0 font-size-12", className)}
      {...rest}
    >
      {children}
    </label>
  );
};
