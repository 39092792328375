import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import "common/scss/index.scss";
import { App, appStore } from "./app";
import * as serviceWorker from "./serviceWorker";
import { Provider } from "react-redux";
require("typeface-open-sans");
require("typeface-barlow");

ReactDOM.render(
  <Router>
    <Provider store={appStore}>
      <App />
    </Provider>
  </Router>,
  document.getElementById("root"),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
