import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { QuoteView, ResultsProps } from "./types";
import { formatDate, SelectedItem } from "../QuoteResults/utils";
import { nanoid } from "nanoid";
import { FileUpload } from "../Upload";

export interface QuotePageState {
  uploadedFile: FileUpload;
  activeStep: QuoteView;
  quoteResultProps: ResultsProps;
  resultsKey: string;
}

export const initialPageState: QuotePageState = {
  quoteResultProps: {
    kerfWeight: 0.1875,
    maxNestedBeamLength: 65,
    gap: 1,
    trimCut: 0.25,
    useStandardLength: false,
    quoteDetails: null,
    requestDate: formatDate(new Date()),
    cityId: "",
    shipTo: "",
    transportation: "01",
    itemsAutoSelected: [],
    itemsNeedReview: [],
    itemsSelected: [],
  },
  uploadedFile: {
    file: null,
    fileType: null,
  },
  activeStep: QuoteView.Upload,
  resultsKey: nanoid(),
};

const quotePageSlice = createSlice({
  name: "quotePage",
  initialState: initialPageState,
  reducers: {
    setQuoteDetails: (state: QuotePageState, action: PayloadAction<ResultsProps>) => {
      state.quoteResultProps = action.payload;
      state.resultsKey = nanoid();
    },
    startOver: (state: QuotePageState) => {
      state = initialPageState;
    },
    setActiveStep: (state: QuotePageState, action: PayloadAction<QuoteView>) => {
      state.activeStep = action.payload;
    },
    saveSelectedFile: (state: QuotePageState, action: PayloadAction<FileUpload>) => {
      state.uploadedFile.file = action.payload.file;
      state.uploadedFile.fileType = action.payload.fileType;
    },
    clearResults: (state: QuotePageState) => {
      state.quoteResultProps.quoteDetails = null;
    },
    moveToResults: (
      state: QuotePageState,
      action: PayloadAction<boolean | undefined> = { payload: false } as PayloadAction<
        boolean | undefined
      >,
    ) => {
      const force = action.payload ?? false;
      if (state.activeStep === QuoteView.Summary || force) {
        state.activeStep = QuoteView.Results;
      }
    },
    resultsToSummary: (state: QuotePageState, action: PayloadAction<SelectedItem[]>) => {
      state.quoteResultProps.itemsSelected = action.payload;
      state.activeStep = QuoteView.Summary;
    },
  },
});

export const {
  setQuoteDetails,
  startOver,
  setActiveStep,
  clearResults,
  moveToResults,
  resultsToSummary,
  saveSelectedFile,
} = quotePageSlice.actions;
export const quotePageReducer = quotePageSlice.reducer;
