import {
  NestingPrefs,
  QuoteCategories,
  QuoteDetails,
  ShipToFormValues,
} from "../QuoteResults/utils";
import { FileUpload } from "../Upload";

export enum QuoteView {
  Upload,
  Results,
  Summary,
}
export type FileInfo = {
  uid?: string;
  name?: string;
  nestedFileUid?: string;
  msAccessToken?: string;
  quoteId?: string;
};

export interface ResultsProps extends ShipToFormValues, NestingPrefs, QuoteCategories {
  quoteDetails: QuoteDetails | null;
}
export interface UnnestedFile {
  uid: string | null;
  mulesoftToken: string | null;
}

export interface MuleError {
  error: {
    code: string;
    type: string;
    description: string;
    uuid: string | null;
  };
}
export interface MuleToken {
  access_token: string;
  scope: string;
  token_type: string;
  expires_in: number;
  error_description: string;
  error: string;
}
export interface MuleResponse<T> {
  message: {
    messageType: string;
    data: T | MuleError;
  };
}
export interface NestingId {
  id: string;
}
export interface FileIds {
  uid: string;
  quoteId: number;
}
export interface NestedPieces {
  length: number;
  quantity: number;
}
export interface NestedCutList {
  waste_pct: number;
  pieces: NestedPieces[];
  mill_mark: string;
  sequence: string;
}
export interface NestedData {
  size: string;
  length: number;
  quantity: number;
  waste_pct: number;
  cut_list: NestedCutList[];
}

export interface FileSpecs extends FileIds {
  fileUpload: FileUpload;
}
