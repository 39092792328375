import React, { useReducer } from "react";
import {
  ContinueHeaderFooter,
  NestingPrefs,
  NotQuotedSection,
  ShipToDateForm,
  ResultsSection,
  MillWeightFooter,
  CutListModal,
  CutListTable,
} from "./components";
import {
  addSelection,
  initialQuoteResultState,
  quoteDetailReducer,
  removeSelection,
  setKerf,
  setMaxNestedBeamLength,
  setUseStandardLength,
  setGap,
  setTrimCut,
  FormFields,
  SelectedItem,
  SelectedOption,
  ShipToFormValues,
  sumAutoSelectedMillWeights,
  Item,
  closeCutList,
  showCutList,
} from "./utils";
import { IconColors, QQSection } from "common/components";
import styles from "./QuoteResults.module.scss";
import clsx from "clsx";
import { ResultsProps } from "../utils";
import { trackFinishQuote } from "common/tagManagerService";

interface QuoteResultsProps extends ResultsProps {
  uid: string;
  uploadedFileName: string;
  enableNesting?: boolean;
  onContinue?: (itemsSelected: SelectedItem[]) => void;
  onQuoteSubmit?: (values: ShipToFormValues) => void;
}

export const QuoteResults: React.FunctionComponent<QuoteResultsProps> = ({
  uid,
  uploadedFileName,
  enableNesting,
  kerfWeight,
  maxNestedBeamLength,
  useStandardLength,
  gap,
  trimCut,
  quoteDetails,
  requestDate,
  cityId,
  shipTo,
  transportation,
  itemsAutoSelected,
  itemsNeedReview,
  itemsSelected,
  onContinue = () => {},
  onQuoteSubmit = () => {},
}) => {
  const [state, dispatch] = useReducer(quoteDetailReducer, {
    ...initialQuoteResultState,
    itemsSelected,
    kerfWeight,
    maxNestedBeamLength,
    useStandardLength,
    gap,
    trimCut,
    millWeights: sumAutoSelectedMillWeights(itemsSelected, quoteDetails?.entities.plantOptions),
  });
  const onContinueClick = async () => {
    trackFinishQuote();
    onContinue(state.itemsSelected);
  };
  const canContinue = () => state.itemsSelected.length > 0;
  const onSelectionChange = (option: SelectedOption) => {
    const { itemUid, optionUid } = option;
    if (option.isSelected) {
      dispatch(
        addSelection({
          selectedItem: { itemUid, optionUid },
          plantOptions: quoteDetails?.entities.plantOptions,
        }),
      );
    } else {
      dispatch(
        removeSelection({
          selectedItem: { itemUid, optionUid },
          plantOptions: quoteDetails?.entities.plantOptions,
        }),
      );
    }
  };
  const handleQuoteSubmit = (values: FormFields) => {
    onQuoteSubmit({
      ...values,
      kerfWeight: state.kerfWeight,
      maxNestedBeamLength: state.maxNestedBeamLength,
      useStandardLength: state.useStandardLength,
      gap: state.gap,
      trimCut: state.trimCut,
    });
  };
  const onCutListClick = (item: Item, quantitySelected: number) => {
    dispatch(showCutList({ item, quantitySelected }));
  };
  const nestingPrefsView = () => {
    return enableNesting ? (
      <NestingPrefs
        kerfWeight={state.kerfWeight}
        maxNestedBeamLength={state.maxNestedBeamLength}
        useStandardLength={state.useStandardLength}
        gap={state.gap}
        trimCut={state.trimCut}
        onKerfChange={(value) => dispatch(setKerf(value))}
        onBeamLengthChange={(value) => dispatch(setMaxNestedBeamLength(value))}
        onGapChange={(value) => dispatch(setGap(value))}
        onTrimCutChange={(value) => dispatch(setTrimCut(value))}
        onUseStandardLengthChange={(value) => dispatch(setUseStandardLength(value))}
      />
    ) : null;
  };

  const resultsView = () => {
    if (quoteDetails !== null) {
      return (
        <>
          <ContinueHeaderFooter disabled={!canContinue()} onClick={onContinueClick} />
          <QQSection green>
            <ResultsSection
              textHeading="Auto Selected"
              itemIds={itemsAutoSelected}
              details={quoteDetails}
              requestDate={requestDate}
              itemsSelected={state.itemsSelected}
              iconColor={IconColors.DARKGREEN}
              onSelectionChange={onSelectionChange}
              onCutListClick={onCutListClick}
              shipTo={shipTo}
              shippingMethod={transportation}
            />
          </QQSection>
          <QQSection yellow>
            <ResultsSection
              textHeading="Needs Review"
              itemIds={itemsNeedReview}
              details={quoteDetails}
              requestDate={requestDate}
              itemsSelected={state.itemsSelected}
              iconColor={IconColors.WARNING}
              onSelectionChange={onSelectionChange}
              onCutListClick={onCutListClick}
              shipTo={shipTo}
              shippingMethod={transportation}
            />
          </QQSection>
          <QQSection red>
            <NotQuotedSection uid={uid} />
          </QQSection>
          <QQSection blue className="p-30 text-light">
            <h5
              className={clsx(styles.disclaimerHeading, "font-size-20 m-0 mb-6 font-weight-bold")}
            >
              Important
            </h5>
            <ul className="font-size-12">
              <li>
                Stock is subject to availability and prior sale. Rollings may change/close without
                prior notice.
              </li>
              <li className="mt-6">
                Standard length products must be ordered in bundle quantities.
              </li>
              <li className="mt-6">Custom length product availability is limited.</li>
              <li className="mt-6">
                Inquire with your Sales Representative for lengths greater than 75 feet.
              </li>
              <li className="mt-6">
                Drop does not include full pieces, Cut List shows component matches.
              </li>
            </ul>
          </QQSection>
          <ContinueHeaderFooter disabled={!canContinue()} onClick={onContinueClick} />
          <MillWeightFooter millWeights={state.millWeights} />
          <CutListModal
            show={state.cutList.show}
            title="Cut List"
            onClose={() => dispatch(closeCutList())}
          >
            <CutListTable
              item={state.cutList.item}
              quantitySelected={state.cutList.quantitySelected}
            />
          </CutListModal>
        </>
      );
    }
  };

  return (
    <>
      {nestingPrefsView()}
      <ShipToDateForm
        fileName={uploadedFileName}
        requestDateInit={requestDate}
        cityIdInit={cityId}
        shipToInit={shipTo}
        transportationInit={transportation}
        onSubmit={handleQuoteSubmit}
      />
      {resultsView()}
    </>
  );
};
