import clsx from "clsx";
import React from "react";
import { Button, QQSection } from "common/components";
import styles from "./ContinueHeaderFooter.module.scss";

type ContinueHeaderFooterProps = {
  text?: string;
  disabled?: boolean;
  buttonText?: string;
  onClick: () => void;
};

export const ContinueHeaderFooter: React.FunctionComponent<ContinueHeaderFooterProps> = ({
  text = "Continue to Review & Finish",
  buttonText = "Continue",
  disabled = true,
  onClick,
}) => {
  return (
    <QQSection blue>
      <div className={clsx(styles.container, "d-flex py-18 px-30")}>
        <div className="left-col flex-grow-1 d-flex align-items-center">
          <p className="m-0 font-size-16">{text}</p>
        </div>
        <div className="text-right flex-fill">
          <Button disabled={disabled} onClick={onClick}>
            {buttonText}
          </Button>
        </div>
      </div>
    </QQSection>
  );
};
