import { Token } from "./Token";
import { pipeAsync } from "./Util";

type JsonResponseFn = (response: Response) => Promise<any>;
type HeadersInit = Headers | string[][] | Record<string, string> | undefined;

const jsonResponse = async (response: Response) => await response.json();

const fetchCall =
  (fetchFn: (input: RequestInfo, init?: RequestInit | undefined) => Promise<Response>) =>
  (init?: RequestInit) =>
  async (input: RequestInfo) =>
    await fetchFn(input, init);

const qqtFetch = fetchCall(fetch);

const genericFetch =
  (init?: RequestInit) => (headersInit?: HeadersInit) => async (input: RequestInfo) => {
    const headers = headersInit ? new Headers(headersInit) : headersInit;
    return await qqtFetch({ ...init, headers })(input);
  };

const fetchWithToken =
  (fetchFn: typeof genericFetch) =>
  (init?: RequestInit | undefined) =>
  (toJsonFn?: JsonResponseFn) =>
  (input: RequestInfo) =>
  async (token: Token) => {
    const headerInit = { Authorization: `Bearer ${token.access_token}` };
    const callFn = fetchFn(init)(headerInit);
    return toJsonFn ? await toJsonFn(await callFn(input)) : await callFn(input);
  };

export const getJsonWithToken = fetchWithToken(genericFetch)({ method: "GET", mode: "cors" })(
  jsonResponse,
);

export const getWithheaders = async <T>(headersInit: HeadersInit, input: RequestInfo) =>
  await pipeAsync(
    genericFetch({ method: "GET", mode: "cors" })(headersInit),
    jsonResponse,
  )<RequestInfo, T>(input);
