import { appState, setCustomer, useAppDispatch, useAppSelector } from "app";
import { useGetEgerdauCustomerQuery } from "common/services";
import { skipToken } from "@reduxjs/toolkit/query/react";
import { useEffect } from "react";

export const useLoadEgerdauCustomer = (accessToken: string | null) => {
  const dispatch = useAppDispatch();
  const { customer } = useAppSelector(appState);
  const { error, data } = useGetEgerdauCustomerQuery(
    !customer && accessToken ? undefined : skipToken,
  );

  useEffect(() => {
    if (data) {
      dispatch(setCustomer(data));
    }
  }, [data, dispatch]);

  return {
    customer,
    error,
  };
};
