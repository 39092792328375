import { QQModal } from "common/components";
import React from "react";

export interface CutListModalProps {
  title?: string;
  show: boolean;
  onClose?: () => void;
}

export const CutListModal: React.FC<CutListModalProps> = ({ title, show, children, onClose }) => {
  return (
    <QQModal
      title={title}
      contentClassName="bg-secondary"
      headerClassName="text-white font-weight-bold font-size-16"
      titleClassName="flex-fill text-center"
      show={show}
      onClose={onClose}
      closeButton
    >
      {children}
    </QQModal>
  );
};
