import {
  appState,
  showError,
  startLoading,
  stopLoading,
  useAppDispatch,
  useAppSelector,
} from "app";
import { useEffect, useState } from "react";
import { useLoadEgerdauToken } from "./useLoadEgerdauToken";
import { useLoadEgerdauCustomer } from "./useLoadEgerdauCustomer";
import { useLoadSAPData } from "./useLoadSAPData";
import { DD_LOGS, DD_RUM } from "app/DataDogRUM";
import { datadogRum } from "@datadog/browser-rum";
import { getEnv } from "common/services";
import { trackCustomDimensions } from "common/tagManagerService";
import { datadogLogs } from "@datadog/browser-logs";

export const useAppInit = () => {
  const dispatch = useAppDispatch();
  const [error, setError] = useState<boolean>(false);
  const [appInitComplete, setAppInitComplete] = useState<boolean>(false);
  const [datadogInitComplete, setDDInitComplete] = useState<boolean>(false);
  const hostname = window?.location.hostname;
  // Datadog initialization
  useEffect(() => {
    if (!datadogInitComplete && !error) {
      dispatch(startLoading({ message: "Loading Datadog" }));
      DD_RUM();
      DD_LOGS();
      setDDInitComplete(true);
    }
  }, [datadogInitComplete, error, dispatch]);
  // eGerdau initialization data
  const { customer, accessToken, sapAuxProfile } = useAppSelector(appState);
  const {
    error: tokenError,
    isRedirect,
    remoteTokenError,
    authCode,
    cachedAccessToken,
  } = useLoadEgerdauToken();

  datadogLogs.logger.info(
    "[eGerdau Login] " +
      "hostname: " +
      hostname +
      ", URL:" +
      window.location.href +
      ", isRedirect: " +
      isRedirect +
      ", authCode: " +
      authCode +
      ", cachedAccessToken present: " +
      !!cachedAccessToken +
      ", appInitComplete: " +
      appInitComplete,
  );
  if (remoteTokenError) {
    datadogLogs.logger.error("[eGerdau Remote Token Error] error - " + remoteTokenError);
  }

  const { error: customerError } = useLoadEgerdauCustomer(accessToken);
  const { error: sapError } = useLoadSAPData(customer);

  useEffect(() => {
    if (!appInitComplete && !error) {
      dispatch(startLoading({ message: "Loading your eGerdau profile" }));
    }
  }, [appInitComplete, error, dispatch]);

  useEffect(() => {
    if (tokenError || customerError || sapError) {
      setError(true);
      const title = "Egerdau Login Failure";
      const message =
        "Unable to process your login at this time, Please refresh your browser or try again later. Token: missing " +
        tokenError +
        " Unable to lookup customer " +
        customerError +
        " SAP: " +
        sapError;
      datadogLogs.logger.error("[eGerdau Login Error]: Egerdau Login Failure", {
        error: message,
        remoteTokenError: remoteTokenError,
      });
      dispatch(
        showError({
          critical: true,
          title: title,
          message: message,
        }),
      );
    }
  }, [tokenError, customerError, sapError, remoteTokenError, dispatch]);

  useEffect(() => {
    if (accessToken && customer && sapAuxProfile) {
      dispatch(stopLoading());
      setAppInitComplete(true);
      const env = getEnv();
      if (env === "PROD" || env === "DEV" || env === "QA") {
        datadogRum.startSessionReplayRecording();
      }
    }
  }, [customer, accessToken, sapAuxProfile, dispatch]);
  useEffect(() => {
    trackCustomDimensions(customer);
  }, [customer]);
  return {
    isRedirect,
    appInitComplete,
  };
};
