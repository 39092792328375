import React from "react";
import { formatLength } from "../utils";
import ProgressBar from "react-bootstrap/ProgressBar";
import { CutListProgressBar } from "./CutListProgressBar";
import clsx from "clsx";
import styles from "./CutListTable.module.scss";

export type CutListRowProps = {
  length: number;
  quantity: number;
  wastePercent: number;
  pieceCount: number;
  rowSpan: number;
  index: number;
  option: any;
  millMark: string;
};

export const CutListTableRow: React.FunctionComponent<CutListRowProps> = ({
  length,
  quantity,
  wastePercent,
  pieceCount,
  rowSpan,
  index,
  option,
  millMark,
}) => {
  const showRowspan = index !== 0 ? true : false;
  return (
    <>
      <tr hidden={index > 0}>
        <td colSpan={6}>
          <ProgressBar>
            {option.pieces.map((piece: { length: number; quantity: number }, index: number) => (
              <CutListProgressBar
                quantity={piece.quantity}
                wastePercent={wastePercent}
                variant={"success"}
              ></CutListProgressBar>
            ))}
            <ProgressBar
              className={clsx(styles.progressBarSpace)}
              variant={"warning"}
              now={wastePercent * rowSpan}
              key={quantity + 1}
              isChild={true}
            />
          </ProgressBar>
        </td>
      </tr>
      <tr>
        <td rowSpan={rowSpan} hidden={showRowspan}>
          {pieceCount}PC
        </td>
        <td>{quantity}</td>
        <td>@</td>
        <td>{formatLength(length)}</td>
        <td rowSpan={rowSpan} hidden={showRowspan}>
          {wastePercent} %
        </td>
        <td>{millMark}</td>
      </tr>
    </>
  );
};
