import { QuoteDetails, SelectedItem } from "../QuoteResults/utils";

export const getSelectedOptions = (
  selectedItems: SelectedItem[],
  quoteDetails: QuoteDetails | null,
) => {
  if (!quoteDetails) return [];
  return selectedItems
    .map((item) => item.optionUid)
    .map((optionId) => quoteDetails.entities.plantOptions[optionId]);
};

export const getSelectedCutList = (
  selectedItems: SelectedItem[],
  quoteDetails: QuoteDetails | null,
) => {
  if (!quoteDetails) return [];
  return selectedItems
    .map((item) => item.itemUid)
    .map((itemId) => quoteDetails.entities.items[itemId]);
};
