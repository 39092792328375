import clsx from "clsx";
import React from "react";
import styles from "./QQLink.module.scss";

interface QQLinkProps extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
  button?: boolean;
  link?: boolean;
}

export const QQLink: React.FC<QQLinkProps> = ({ className, children, button, link, ...rest }) => {
  return (
    <a
      className={clsx(
        {
          "btn-primary btn py-8 px-26 m-0": button,
          "cursor-pointer text-primary text-decoration-none": link,
          [styles.lineHeight1]: button,
        },
        className,
      )}
      {...rest}
    >
      {children}
    </a>
  );
};
