import { Notification, NotifyType } from "common/components";
import React from "react";
import { PlantOption } from "../utils";

interface QuantityNotificationProps {
  option: PlantOption;
}
export const QuantityNotification: React.FunctionComponent<QuantityNotificationProps> = ({
  option,
}) => {
  let difference: number;

  if (option.pieces === option.orderQuantity) {
    return <Notification type={NotifyType.Success} text="Exact Count" />;
  } else if (option.pieces > option.orderQuantity) {
    difference = option.pieces - option.orderQuantity;
    return <Notification type={NotifyType.Error} text={`Under ${difference} PC`} />;
  }

  difference = option.orderQuantity - option.pieces;
  return <Notification type={NotifyType.Error} text={`Over ${difference} PC`} />;
};
