import React, { useRef } from "react";
import { QQSection } from "common/components";
import { SearchButtonGroup } from "./SearchButtonGroup";
import { SearchForm } from "./SearchForm";

interface LookupHeaderProps {
  onButtonGroupClick?: (
    index: number,
    name: string,
    currentSearchValue: string | undefined,
  ) => void;
  onSearchSubmit?: (value: string) => void;
  onCleared?: () => void;
  hasError: boolean;
  errorMessage?: string;
  searchBtnLabels: string[];
  selectedBtnLabel: string;
  searchBtnGroupHeading?: string;
}

export const LookupHeader: React.FC<LookupHeaderProps> = ({
  onButtonGroupClick = () => {},
  onSearchSubmit = () => {},
  onCleared = () => {},
  hasError,
  errorMessage,
  searchBtnLabels,
  selectedBtnLabel,
  searchBtnGroupHeading = "View All Quotes Created",
}) => {
  const searchRef = useRef<HTMLInputElement>(null);
  const onButtonClick = (index: number, name: string) => {
    onButtonGroupClick(index, name, searchRef.current?.value);
  };

  return (
    <QQSection blue>
      <div className="d-flex px-28 py-42">
        <div className="flex-grow-1">
          <SearchForm
            hasError={hasError}
            errorMessage={errorMessage}
            searchRef={searchRef}
            onSubmit={onSearchSubmit}
            onCleared={onCleared}
          />
        </div>
        <div className="flex-grow-1 ml-4">
          <SearchButtonGroup
            labels={searchBtnLabels}
            heading={searchBtnGroupHeading}
            onButtonClick={onButtonClick}
            selectedLabel={selectedBtnLabel}
          />
        </div>
      </div>
    </QQSection>
  );
};
