import { Label } from "common/components";
import React, { useState } from "react";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import { UseFormRegister, UseFormSetValue } from "react-hook-form";
import { getUrlWithQuery, getWithheaders } from "common/services";
import { FormFields, GeoCitiesURL, RapidAPIHostCities, RapidApiKey } from "../utils";
import "./ShipToTypeAhead.scss";
import { datadogLogs } from "@datadog/browser-logs";

//City search
const CountryIds = "US,CA";
const Limit = "15";
//Include Deleted Options: ALL | SINCEYESTERDAY | SINCELAST_WEEK | NONE
const IncludeDeleted = "NONE";
//SORT options: countryCode | elevation | name | population
//Sorting by most populous city yields more relevant results if search request is broad
const Sort = "-population";
//Options: CITY | ADM2
const SearchType = "CITY";
const ShipToRegex = /^([A-Za-zÀ-ú'".-]\s*)+,{1}\s*[A-Za-z]{2}\s*$/g;

interface ShipToTypeAheadProps {
  register: UseFormRegister<FormFields>;
  setValue: UseFormSetValue<FormFields>;
  labelText: string;
  defaultShipToValue?: string;
  onCityIdChange?: (id: string) => void;
}

interface GeoDbCity {
  id: number;
  value: string;
}

export const ShipToTypeAhead: React.FunctionComponent<ShipToTypeAheadProps> = ({
  register,
  setValue,
  labelText,
  defaultShipToValue,
  onCityIdChange = () => {},
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState<GeoDbCity[]>([]);
  const { onBlur } = register("shipTo", {
    required: true,
    pattern: ShipToRegex,
  });
  const filterBy = () => true;
  const handleCitySearch = async (query: string) => {
    try {
      const stateCommaIdx = query.indexOf(",");
      let cityValue;
      if (stateCommaIdx > -1) {
        cityValue = query.substring(0, stateCommaIdx);
      } else {
        cityValue = query;
      }

      setIsLoading(true);
      const resp = await getWithheaders<any>(
        {
          "x-rapidapi-key": RapidApiKey,
          "x-rapidapi-host": RapidAPIHostCities,
        },
        getUrlWithQuery(GeoCitiesURL, {
          limit: Limit,
          countryIds: CountryIds,
          includeDeleted: IncludeDeleted,
          namePrefix: cityValue,
          sort: Sort,
          types: SearchType,
        }),
      );

      setOptions(
        resp.data.map((item: any) => ({
          id: item.id,
          value: `${item.city.split(",")[0]}, ${item.regionCode}`,
        })),
      );
    } catch (error) {
      datadogLogs.logger.error("[Ship To type Ahead]: Error with city search", {
        name: "Error: City search",
        details: error,
      });
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };
  const onChange = (selected: GeoDbCity[]) => {
    if (selected.length > 0) {
      onCityIdChange(selected[0].id.toString());
      setValue("shipTo", selected[0].value, { shouldValidate: true, shouldDirty: true });
    } else {
      onCityIdChange("");
      setValue("shipTo", "", { shouldValidate: true, shouldDirty: true });
    }
  };
  return (
    <>
      <Label htmlFor="ship-to" className="mb-4">
        {labelText}
      </Label>
      <AsyncTypeahead
        id="ship-to"
        isLoading={isLoading}
        defaultInputValue={defaultShipToValue ?? ""}
        delay={350}
        placeholder="Enter Ship To City, ST..."
        searchText="Searching"
        minLength={3}
        useCache={true}
        onSearch={handleCitySearch}
        options={options}
        filterBy={filterBy}
        labelKey={(option) => `${option.value}`}
        onChange={onChange}
        onBlur={onBlur}
      />
    </>
  );
};
