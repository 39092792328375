import React from "react";
import Modal from "react-bootstrap/Modal";
import divWithClassName from "react-bootstrap/divWithClassName";
import styles from "./QQModal.module.scss";
import clsx from "clsx";

export interface QQModalProps {
  title?: React.ReactNode;
  show: boolean;
  closeButton?: boolean;
  contentClassName?: string;
  headerClassName?: string;
  titleClassName?: string;
  bodyClassName?: string;
  size?: "sm" | "lg" | "xl";
  footer?: React.ReactNode;
  onClose?: () => void;
}

export const QQModal: React.FC<QQModalProps> = ({
  title,
  show,
  closeButton,
  contentClassName,
  headerClassName,
  titleClassName,
  bodyClassName,
  size,
  footer = null,
  children,
  onClose = () => {},
}) => {
  const onHide = () => {
    if (closeButton) {
      onClose();
    }
  };
  return (
    <Modal
      {...{ show, size, onHide }}
      contentClassName={clsx(styles.borderTop, styles.bottomRounded, contentClassName)}
    >
      <Modal.Header
        bsPrefix={styles.modalheader}
        className={clsx(
          headerClassName,
          "d-flex align-items-center justify-content-between py-8 px-10",
        )}
        closeButton={closeButton}
      >
        <Modal.Title as={divWithClassName(clsx("p-0", titleClassName))}>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body as={divWithClassName(clsx(styles.bottomRounded, "p-0", bodyClassName))}>
        {children}
      </Modal.Body>
      {footer && (
        <Modal.Footer>
          {
            <span className="d-inline-block">
              <button
                className={clsx(
                  styles.modalFooter,
                  "font-size-12 m-0 pr-100 pl-100 btn btn-primary",
                )}
                type="button"
                onClick={onHide}
              >
                Ok
              </button>
            </span>
          }
        </Modal.Footer>
      )}
    </Modal>
  );
};
