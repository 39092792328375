import clsx from "clsx";
import React from "react";
import logo from "assets/logo_gerdau_blue_horizontal.svg";
import styles from "./Nav.module.scss";
import { IconColors, SvgIcon } from "common/components";

export const NavComponent: React.FC = () => {
  return (
    <div className={clsx(styles.qqtNav, "d-flex bg-white position-fixed")}>
      <div className="flex-fill">
        <a href="/">
          <img
            src={logo}
            alt="Quick Quote Tool Logo"
            className={clsx("m-0 my-10 ml-10", styles.qqtLogo)}
          />
        </a>
      </div>
      <div className="font-size-12 mb-6 align-self-end">
        <a href="/how-to" className="d-inline-block text-decoration-none mr-22">
          <span className="d-inline-flex align-items-center">
            <SvgIcon
              name="info-alert"
              attributes={{ width: "15px", height: "15px", fill: IconColors.PRIMARY }}
              className="mr-4"
            />
            <span>HOW TO</span>
          </span>
        </a>
        <a href="http://www.egerdau.com" className="d-inline-block text-decoration-none mr-34">
          <span className="d-inline-flex align-items-center">
            <SvgIcon
              name="home"
              attributes={{ width: "18px", height: "15px", fill: IconColors.PRIMARY }}
              className="mr-4"
            />
            <span>eGERDAU</span>
          </span>
        </a>
      </div>
    </div>
  );
};
