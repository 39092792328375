import { EgerdauToken } from "./EgerdauApi";
import { getLocal } from "./LocalStorage";

export interface Token extends EgerdauToken {
  expirationDate: string;
}

export const getFutureDate = (seconds: number, now: Date = new Date()) => {
  now.setSeconds(now.getSeconds() + seconds);
  return now.toISOString();
};
const setExpirationDate =
  (futureDate: typeof getFutureDate) =>
  (token: EgerdauToken | null, mockNow: Date | undefined = undefined): Token | null =>
    token === null ? null : { ...token, expirationDate: futureDate(token.expires_in, mockNow) };
const pullLocalToken = (key: string) => getLocal<Token | null>(key);

export const isTokenExpired = (token: Token | null, now: Date = new Date()) => {
  try {
    if (token) return new Date(token.expirationDate) <= now;
  } catch {}
  return true;
};

export const getLocalToken = (key: string, mockNow: Date | undefined = undefined) => {
  const token = pullLocalToken(key);

  if (token) return isTokenExpired(token, mockNow) ? null : token.access_token;
  return null;
};

export const setTokenExpirationDate = setExpirationDate(getFutureDate);
