import clsx from "clsx";
import React from "react";
import styles from "./QQSection.module.scss";

interface QQSectionProps extends React.HTMLAttributes<HTMLDivElement> {
  blue?: boolean;
  green?: boolean;
  yellow?: boolean;
  red?: boolean;
  white?: boolean;
  bottomSection?: boolean;
  separator?: boolean;
}

export const QQSection: React.FC<QQSectionProps> = ({
  children,
  className,
  blue = false,
  green = false,
  yellow = false,
  red = false,
  white = false,
  bottomSection = false,
  separator = true,
  ...rest
}) => {
  return (
    <div
      className={clsx(
        "font-weight-normal",
        {
          "bg-info": blue,
          "bg-success": green,
          "bg-warning": yellow,
          "bg-danger": red,
          "bg-white": white || (!blue && !yellow && !green && !red && !white),
          [styles.bottom]: bottomSection,
          [styles.separator]: separator,
        },
        className,
      )}
      {...rest}
    >
      {children}
    </div>
  );
};
