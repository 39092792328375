import clsx from "clsx";
import { QQLink, QQSection } from "common/components";
import React from "react";
import { QuoteResultsDownloadUri } from "./constants";
import { trackCreateQuote, trackDownloadQuote } from "common/tagManagerService";

interface SummaryLinkSectionProps {
  uid: string;
}

export const SummaryLinkSection: React.FC<SummaryLinkSectionProps> = ({ uid }) => {
  return (
    <QQSection blue className={clsx("d-flex justify-content-end align-items-center py-14 px-20")}>
      <span className="d-inline-block mr-32">
        <QQLink
          onClick={() => trackCreateQuote()}
          link
          href="/"
          className="font-size-14 font-weight-bold"
        >
          Create Another Quote
        </QQLink>
      </span>
      <span>
        <QQLink
          onClick={() => trackDownloadQuote()}
          button
          href={`${QuoteResultsDownloadUri(uid)}`}
          download="export"
          className="font-size-14"
        >
          Download Quote
        </QQLink>
      </span>
    </QQSection>
  );
};
