export const transportOptions = [
  { id: "truck", label: "Truck", value: "01" },
  { id: "pick-up", label: "Pick Up", value: "04" },
  { id: "rail", label: "Rail", value: "03" },
];

// Utility function to map value to label
export const getTransportLabel = (value: string) => {
  const option = transportOptions.find((opt) => opt.value === value);
  return option ? option.label : value;
};
