import { ContactSalesRep } from "./constants";
import { QuoteDetails, SelectedItem, toDate } from "../QuoteResults/utils";

const parsePoundsPerFoot = (size: string) =>
  Number(size.replace(/\s/, "").toLowerCase().split("x")[1]);

export const calculateBundleWeight = (length: number, size: string, bundleQty: number) => {
  let lbsPerFoot = parsePoundsPerFoot(size);
  let fractionToWholeInches = Number((Math.abs(length) - Math.trunc(length)).toFixed(2)) * 100;
  let feet = Math.trunc(length);
  if (fractionToWholeInches > 0) {
    feet = feet + +(fractionToWholeInches / 12).toFixed(2);
  }
  return +(feet * lbsPerFoot * bundleQty).toFixed();
};

export const isExactMatch = (
  dateAvailable: string,
  pieces: number,
  orderQuantity: number,
  requestedDate: string,
) => orderQuantity === pieces && toDate(dateAvailable) <= toDate(requestedDate);

export const createQuoteCategories = (quoteDetail: QuoteDetails | null, requestDate: string) => {
  const itemsSelected: SelectedItem[] = [];
  const itemsAutoSelected: string[] = [];
  const itemsNeedReview: string[] = [];

  if (quoteDetail !== null) {
    const {
      entities: { items, itemPlants, plantOptions },
      result,
    } = quoteDetail;

    for (const itemId of result) {
      const item = items[itemId];
      let hasExactMatch = false;

      if (item.item_exists !== ContactSalesRep) {
        if (!itemsAutoSelected.includes(itemId)) {
          const plantIds = items[itemId].options;
          for (const plantId of plantIds) {
            const optionIds = itemPlants[plantId].options;
            for (const optionId of optionIds) {
              const option = plantOptions[optionId];
              if (
                isExactMatch(option.date_available, item.pieces, option.orderQuantity, requestDate)
              ) {
                itemsSelected.push({ itemUid: itemId, optionUid: option.uid });
                itemsAutoSelected.push(itemId);
                hasExactMatch = true;
                break;
              }
            }
            if (hasExactMatch) break;
          }
        }
        if (!hasExactMatch) itemsNeedReview.push(itemId);
      }
    }
  }
  return {
    itemsSelected,
    itemsAutoSelected,
    itemsNeedReview,
  };
};
