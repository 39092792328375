import React from "react";
import clsx from "clsx";
import styles from "./RadioButton.module.scss";

interface RadioButtonProps extends React.InputHTMLAttributes<HTMLInputElement> {
  ref?: React.RefObject<HTMLInputElement>;
  large?: boolean;
}

export const RadioButton = React.forwardRef<HTMLInputElement, RadioButtonProps>(
  ({ className, type, large = false, ...rest }, ref) => {
    return (
      <input
        className={clsx(
          styles.container,
          { [styles.large]: large },
          "form-check-input ml-0 bg-white",
          className,
        )}
        ref={ref}
        type="radio"
        {...rest}
      />
    );
  },
);
