import { EgerdauCustomer, EgerdauToken, EgerdauCookie } from "./EgerdauApi";
import { SAPAuxProfile, SoldToDetail } from "./QQApi";

export const AccessTokenMock = "T35T";

export const CustomerMock: EgerdauCustomer = {
  type: "userWsDTO",
  name: "IDC Test salesrep",
  uid: "salesrep@idc.com",
  currency: {
    active: false,
    isocode: "USD",
    name: "US Dollar",
    symbol: "$",
  },
  firstName: "IDC Test",
  language: {
    active: true,
    isocode: " en",
    name: "English",
    nativeName: "English",
  },
  lastName: "salesrep",
  usertype: "salesRep",
};

export const SAPSoldToData: SoldToDetail = {
  salesOrg: "USMI",
  distChannel: "10",
  shippingCondition: "T1",
  incoTerms: "CIF",
  currencyType: "USD",
  paymentTerms: "D010",
};

export const SAPAuxilaryData: SAPAuxProfile = {
  ...SAPSoldToData,
  shipTo: "0100948867",
  soldTo: "0100948867",
};

export const TokenMock: EgerdauToken = {
  access_token: "0fec3a10-066c-4a63-98d6-7841b4e0ed9G",
  token_type: "bearer",
  refresh_token: "49b34f05-eca4-403f-9171-d1a9688184FF",
  expires_in: 33078,
  scope: "basic openid",
};

export const EgerdauCookieMock: EgerdauCookie = {
  soldToUnit: "0100740421",
  customerEmailId: "mock.a.sepulveda@pacificsteelgroup.com",
};
