import clsx from "clsx";
import styles from "./ErrorModal.module.scss";
import { QQModal, SvgIcon } from ".";

export interface ErrorModalProps {
  title?: string;
  message: React.ReactNode;
  show: boolean;
  closeButton?: boolean;
  onClose?: () => void;
}

export const ErrorModal: React.FC<ErrorModalProps> = ({ message, title, ...others }) => {
  return (
    <QQModal
      {...others}
      title={<SvgIcon name="errorWhite" attributes={{ width: "15px", height: "15px" }} />}
      size="sm"
      headerClassName={clsx(styles.modalHeader, styles.modalTitle, "font-size-12 p-10")}
      bodyClassName={clsx(styles.borderTop, "bg-white font-size-12 p-14 text-center")}
      footer={true}
    >
      <div className="font-weight-bold pb-3"> {title} </div>

      {message}
    </QQModal>
  );
};
