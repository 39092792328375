import clsx from "clsx";
import React from "react";
import { IconColors, SvgIcon } from ".";

export enum NotifyType {
  Error,
  Success,
}
interface NotificationProps {
  text: string;
  type: NotifyType;
  classname?: string;
  width?: string | number;
  height?: string | number;
}

export const Notification: React.FunctionComponent<NotificationProps> = ({
  text,
  classname,
  type,
  width = "10px",
  height = "10px",
}) => {
  return (
    <span className="d-inline-flex align-items-center">
      {type === NotifyType.Error ? (
        <SvgIcon name="error" attributes={{ width, height }} className={clsx("mr-3", classname)} />
      ) : (
        <SvgIcon
          name="check-complete"
          attributes={{
            width,
            height,
            fill: IconColors.DARKGREEN,
          }}
          className={clsx("mr-3", classname)}
        />
      )}
      <span>{text}</span>
    </span>
  );
};
