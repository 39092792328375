import React from "react";
import { TabPanel } from "./TabPanel";

export const TabPanels: React.FunctionComponent = (props) => {
  return (
    <div>
      {React.Children.map(props.children, (child, index) => {
        if (React.isValidElement(child)) {
          if (child.type === TabPanel) {
            if (child.props.index === undefined) {
              return React.cloneElement(child, { index: index });
            }
          }
          return child;
        }
        return child;
      })}
    </div>
  );
};
