import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "app";
import {
  AccessTokenMock,
  canSkipLoading,
  CustomerMock,
  EgerdauCustomer,
  getLocalToken,
  SAPAuxProfile,
  SAPAuxilaryData,
  TokenKey,
  EgerdauCookie,
  EgerdauCookieMock,
} from "common/services";

interface LoadingAction {
  message?: string | null;
  messageDetail?: string | null;
}
interface LoadingState extends LoadingAction {
  show: boolean;
}

interface ErrorAction {
  title?: string;
  message: React.ReactNode;
  critical?: boolean;
}

interface ErrorState extends ErrorAction {
  show: boolean;
}

export interface AppState {
  loading: LoadingState;
  error: ErrorState;
  accessToken: string | null;
  egerdauCookie: EgerdauCookie | null;
  customer: EgerdauCustomer | null;
  sapAuxProfile: SAPAuxProfile | null;
}

const mockLocal = canSkipLoading("LOCAL", "PPRD");

const initialState: AppState = {
  loading: { show: false, message: null },
  error: {
    title: undefined,
    message: null,
    show: false,
    critical: false,
  },
  accessToken: mockLocal ? AccessTokenMock : getLocalToken(TokenKey),
  egerdauCookie: mockLocal ? EgerdauCookieMock : null,
  customer: mockLocal ? CustomerMock : null,
  sapAuxProfile: mockLocal ? SAPAuxilaryData : null,
};

const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    startLoading: (state: AppState, action: PayloadAction<LoadingAction>) => {
      state.loading.show = true;
      state.loading.message = action.payload.message;
      state.loading.messageDetail = action.payload.messageDetail;
    },
    setLoadingMessage: (state: AppState, action: PayloadAction<string>) => {
      state.loading.message = action.payload;
    },
    setAccessToken: (state: AppState, action: PayloadAction<string>) => {
      state.accessToken = action.payload;
    },
    setEgerdauCookie: (state: AppState, action: PayloadAction<EgerdauCookie>) => {
      state.egerdauCookie = action.payload;
    },
    setCustomer: (state: AppState, action: PayloadAction<EgerdauCustomer | null>) => {
      state.customer = action.payload;
    },
    setAuxConfiguration: (state: AppState, action: PayloadAction<SAPAuxProfile | null>) => {
      state.sapAuxProfile = action.payload;
    },
    stopLoading: (state: AppState) => {
      if (state.loading.show) {
        state.loading.show = false;
        state.loading.message = null;
        state.loading.messageDetail = null;
      }
    },
    showError: (state: AppState, action: PayloadAction<ErrorAction>) => {
      state.error.show = true;
      state.error.critical =
        action.payload.critical === undefined ? false : action.payload.critical;
      state.error.message = action.payload.message;
      state.error.title = action.payload.title;
    },
    closeError: (state: AppState) => {
      state.error.critical = false;
      state.error.message = null;
      state.error.title = undefined;
      state.error.show = false;
    },
  },
});

export const appState = (state: RootState) => state.app;

export const {
  startLoading,
  setLoadingMessage,
  setAccessToken,
  setEgerdauCookie,
  setCustomer,
  setAuxConfiguration,
  stopLoading,
  showError,
  closeError,
} = appSlice.actions;
export const appReducer = appSlice.reducer;
