import clsx from "clsx";
import React from "react";
import { Tab } from "./Tab";

type TabListProps = {
  selectedTabIndex: number;
};
export const TabList: React.FunctionComponent<TabListProps> = ({ selectedTabIndex, children }) => {
  const isActive = (index: number) => selectedTabIndex === index;
  const isComplete = (index: number) => selectedTabIndex > index;
  const isInactive = (index: number) => !isActive(index) && !isComplete(index);

  return (
    <ul className={clsx("m-0 p-0 d-flex pt-18 pb-16 list-unstyled")}>
      {React.Children.map(children, (child, index) => {
        if (React.isValidElement(child)) {
          if (child.type === Tab) {
            return React.cloneElement(child, {
              children: () =>
                child.props.children(isActive(index), isComplete(index), isInactive(index)),
              key: index,
            });
          }
          return child;
        } else {
          return child;
        }
      })}
    </ul>
  );
};
