import { MillInfo, ApiUrl } from "common/services";
import { MillWeights } from "./types";

export const MillMap = new Map<number, MillInfo>([
  [1323, { name: "Cartersville", cityState: "Cartersville, GA" }],
  [1327, { name: "Midlothian", cityState: "Midlothian, TX" }],
  [1330, { name: "Petersburg", cityState: "Petersburg, VA" }],
]);

export const MillWeightInit: MillWeights = {
  1323: 0,
  1327: 0,
  1330: 0,
};
export const RapidApiKey = "e8fb1ee009msh753865bd6e3597dp1e98fejsn1ff25563d9cb";
export const RapidAPIHostCities = "wft-geo-db.p.rapidapi.com";
export const GeoCitiesURL = "https://wft-geo-db.p.rapidapi.com/v1/geo/cities";

export const ItemsUri = (uid: string) => `${ApiUrl}/file/items/${uid}`;
