import React from "react";
import { NavComponent } from "./Nav";
import { Switch, Route, Redirect } from "react-router-dom";
import { ErrorModal, SpinningLoader } from "common/components";
import { closeError, appState, useAppDispatch, useAppSelector } from "app";
import { QuotePage } from "features/Quote";
import { HowToPage } from "features/Tutorial";
import { getEnv, redirectToEgerdau } from "common/services";
import { QuoteLookup } from "features/QuoteLookup";
import { useAppInit } from "hooks";

export const App: React.FC = () => {
  const dispatch = useAppDispatch();
  const { error, loading, customer } = useAppSelector(appState);
  const { isRedirect, appInitComplete } = useAppInit();

  if (isRedirect) redirectToEgerdau();
  const isValidEnv = () => getEnv() !== "UNKNOWN";

  return (
    <div className="App">
      <ErrorModal
        title={error.title}
        message={error.message}
        show={error.show}
        closeButton={!error.critical}
        onClose={() => dispatch(closeError())}
      />
      <SpinningLoader
        message={loading.message}
        messageDetail={loading.messageDetail}
        show={loading.show}
      />

      {isValidEnv() && (
        <>
          <NavComponent />
          {appInitComplete && (
            <Switch>
              <Route exact path="/">
                <QuotePage />
              </Route>
              <Route exact path="/how-to">
                <HowToPage />
              </Route>
              <Route exact path="/lookup">
                {customer?.usertype === "salesRep" && <QuoteLookup />}
              </Route>
              <Route path="*">
                <Redirect to="/" />
              </Route>
            </Switch>
          )}
        </>
      )}
    </div>
  );
};
