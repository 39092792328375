import clsx from "clsx";
import React from "react";
import { SvgIcon } from "./SvgIcon";
import styles from "./SpinningLoader.module.scss";

type SpinningLoaderProps = {
  message?: string | null;
  messageDetail?: string | null;
  show: boolean;
  style?: React.CSSProperties;
  className?: string;
  attributes?: React.SVGProps<SVGSVGElement>;
};

export const SpinningLoader: React.FunctionComponent<SpinningLoaderProps> = ({
  message,
  messageDetail,
  show = false,
  style,
  className,
  attributes = { width: "30px", height: "30px", viewBox: "0 0 30 30" },
}) => {
  return show ? (
    <div
      className={clsx(
        styles.loading,
        "position-fixed d-flex flex-column align-items-center w-100 h-100",
        className,
      )}
      style={style}
    >
      <div
        className={clsx(
          styles.content,
          "d-flex flex-column position-relative align-items-center w-100",
        )}
      >
        <SvgIcon name="loading-spinner" attributes={attributes} />
        <h4 className="font-size-24 font-weight-bold">{message}</h4>
        <h5 className="font-size-20 text-dark font-weight-bold m-0">{messageDetail}</h5>
      </div>
    </div>
  ) : null;
};
