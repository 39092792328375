import React from "react";

interface HowToSectionProps {
  headingText: string;
  anchorId: string;
  altText: string;
  imageSrc: string;
}
export const HowToSection: React.FC<HowToSectionProps> = ({
  headingText,
  anchorId,
  imageSrc,
  altText,
  children,
}) => {
  return (
    <div className="mt-104">
      <h4 id={anchorId} className="font-size-58 font-weight-normal text-secondary mb-10">
        {headingText}
      </h4>
      {children}
      <div className="pt-20">
        <img src={imageSrc} alt={altText} />
      </div>
    </div>
  );
};
