import { datadogLogs } from "@datadog/browser-logs";
import { ErrorMessage, getErrorMessage } from "./Errors";

const jsonResponse = async (response: Response) => await response.json();

interface QQTApiResponse {
  data: any;
  error: ErrorMessage | null;
}

const validateResponse = async (response: Promise<any>) => {
  const result: QQTApiResponse = { data: null, error: null };
  try {
    const { data, error } = await response;
    if (data) result.data = data;
    if (error) {
      datadogLogs.logger.error("[APIData]: Error", {
        name: "Error : Validate Response",
        details: error,
      });
      result.error = getErrorMessage(error);
    }
  } catch (error: any) {
    datadogLogs.logger.error("[APIData]: Error", {
      name: "Error in API Data: Validate Response",
      details: error,
    });
    result.error = getErrorMessage(error);
  }
  return result;
};

export const getData = async (url: string) => {
  let fetchData: Response | undefined = undefined;
  let result: QQTApiResponse = { data: null, error: null };
  try {
    fetchData = await fetch(url, { mode: "cors" });
  } catch (ex: any) {
    const errorDetails = getErrorMessage("");
    datadogLogs.logger.error("[APIData]: Error", {
      name: "Error in API Data: Invalid Response",
      details: errorDetails,
    });
    result = { data: null, error: errorDetails };
  }
  if (fetchData !== undefined) {
    result = await validateResponse(jsonResponse(fetchData));
  }
  return result;
};
export const getWithHeaders = async <T>(url: string, headers: HeadersInit) =>
  (await jsonResponse(await fetch(url, { mode: "cors", headers }))) as T;

export const postData = async (url: string, body?: BodyInit) =>
  await validateResponse(post(url, body));
export const post = async (url: string, body?: BodyInit, headers?: HeadersInit) =>
  await jsonResponse(await fetch(url, { method: "POST", mode: "cors", body, headers }));
export const postNoResponse = async (input: RequestInfo, body?: BodyInit) => {
  let error: string | null = null;
  try {
    const data: { error: string } | null = await jsonResponse(
      await fetch(input, { method: "POST", mode: "cors", body }),
    );
    if (data && "error" in data) {
      datadogLogs.logger.error("[APIData]: Error in Post No response", {
        name: "Error : Post no Response",
        details: "Error: " + error + " Data " + data,
      });
      error = data.error;
    }
  } catch (ex: any) {
    datadogLogs.logger.error("[APIData]: Error", {
      name: "Error in API Data: Post no response",
      details: ex,
    });
    error = ex;
  }
  return error;
};
