import { datadogRum } from "@datadog/browser-rum";
import { datadogLogs } from "@datadog/browser-logs";

import { getEnv } from "common/services";

export const envValue = getEnv();
export const envDatadogValue =
  envValue === "PROD" ? "prod" : envValue === "QA" || envValue === "PPRD" ? "qa" : "dev";

export const DD_RUM = () => {
  return datadogRum.init({
    applicationId: "f2e1e9cc-0730-4024-9bdc-dfdb8f609660",
    clientToken: "pub267d39adba1c5ce63f80fd50317b57b4",
    site: "datadoghq.com",
    env: envDatadogValue,
    sampleRate: 100,
    premiumSampleRate: 100, // if not included, the default is 100
    service: "sales-quick-quote-web",
  });
};

export const DD_LOGS = () => {
  return datadogLogs.init({
    clientToken: "pub267d39adba1c5ce63f80fd50317b57b4",
    site: "datadoghq.com",
    env: envDatadogValue,
    sampleRate: 100,
    forwardErrorsToLogs: true,
    service: "sales-quick-quote-web",
  });
};
