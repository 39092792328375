import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  NestingPrefs,
  SelectedItem,
  MillWeights,
  NormalizedObjects,
  PlantOption,
  CutListModal,
  CutListParams,
} from "./types";
import { MillWeightInit } from "./constants";
import { getTotalOptionWeight } from "./helpers";

interface QuoteResultState extends NestingPrefs {
  itemsSelected: SelectedItem[];
  millWeights: MillWeights;
  cutList: CutListModal;
}

interface OptionSelection {
  selectedItem: SelectedItem;
  plantOptions?: NormalizedObjects<PlantOption>;
}
const initCutListModalState = {
  item: null,
  show: false,
  quantitySelected: 0,
};
export const initialQuoteResultState: QuoteResultState = {
  kerfWeight: undefined,
  maxNestedBeamLength: undefined,
  useStandardLength: false,
  gap: undefined,
  trimCut: undefined,
  itemsSelected: [],
  millWeights: MillWeightInit,
  cutList: { ...initCutListModalState },
};

const quoteResultSlice = createSlice({
  name: "quoteResults",
  initialState: initialQuoteResultState,
  reducers: {
    setKerf: (state: QuoteResultState, action: PayloadAction<number>) => {
      state.kerfWeight = action.payload;
    },
    setMaxNestedBeamLength: (state: QuoteResultState, action: PayloadAction<number>) => {
      state.maxNestedBeamLength = action.payload;
    },
    setUseStandardLength: (state: QuoteResultState, action: PayloadAction<boolean>) => {
      state.useStandardLength = action.payload;
    },
    setGap: (state: QuoteResultState, action: PayloadAction<number>) => {
      state.gap = action.payload;
    },
    setTrimCut: (state: QuoteResultState, action: PayloadAction<number>) => {
      state.trimCut = action.payload;
    },
    addSelection: (state: QuoteResultState, action: PayloadAction<OptionSelection>) => {
      const { optionUid } = action.payload.selectedItem;
      if (action.payload.plantOptions) {
        const option = action.payload.plantOptions[optionUid];
        state.itemsSelected.push(action.payload.selectedItem);
        state.millWeights[option.plant] += getTotalOptionWeight(option);
      }
    },
    removeSelection: (state: QuoteResultState, action: PayloadAction<OptionSelection>) => {
      const { itemUid, optionUid } = action.payload.selectedItem;
      if (action.payload.plantOptions) {
        const option = action.payload.plantOptions[optionUid];
        const index = state.itemsSelected.findIndex(
          (option) => option.itemUid === itemUid && option.optionUid === optionUid,
        );
        if (index !== -1) {
          state.itemsSelected.splice(index, 1);
          state.millWeights[option.plant] -= getTotalOptionWeight(option);
        }
      }
    },
    showCutList: (state: QuoteResultState, action: PayloadAction<CutListParams>) => {
      state.cutList.show = true;
      state.cutList.item = action.payload.item;
      state.cutList.quantitySelected = action.payload.quantitySelected;
    },
    closeCutList: (state: QuoteResultState) => {
      state.cutList = { ...initCutListModalState };
    },
  },
});

export const {
  setKerf,
  setMaxNestedBeamLength,
  setUseStandardLength,
  setGap,
  setTrimCut,
  addSelection,
  removeSelection,
  showCutList,
  closeCutList,
} = quoteResultSlice.actions;
export const quoteDetailReducer = quoteResultSlice.reducer;
