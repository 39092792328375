import clsx from "clsx";
import { Button, Label, QQSection, RadioButton, ToolTip } from "common/components";
import React, { useState } from "react";
import { SubmitHandler, useForm, UseFormRegister } from "react-hook-form";
import { CalendarInput, ShipToTypeAhead } from ".";
import styles from "./ShipToDateForm.module.scss";
import { FormFields, transportOptions } from "../utils";
import { trackGenerateQuote } from "common/tagManagerService";

interface ShipDateFormProps {
  fileName: string;
  cityIdInit?: string;
  requestDateInit: string;
  shipToInit?: string;
  transportationInit?: string;
  onSubmit?: (values: FormFields) => void;
}

const transportRadios = (register: UseFormRegister<FormFields>) => {
  const { ref, ...restProps } = register("transportation");
  return (
    <ul className="d-flex mt-7">
      {transportOptions.map((option) => (
        <li key={option.id} className="form-check form-check-inline">
          <RadioButton id={option.id} value={option.value} ref={ref} {...restProps} />
          <Label htmlFor={option.id}>{option.label}</Label>
        </li>
      ))}
    </ul>
  );
};

export const ShipToDateForm: React.FunctionComponent<ShipDateFormProps> = ({
  fileName,
  cityIdInit,
  requestDateInit,
  shipToInit,
  transportationInit,
  onSubmit = () => {},
}) => {
  const [cityId, setCityId] = useState<string>(cityIdInit ?? "");
  const { register, setValue, watch, handleSubmit } = useForm<FormFields>({
    defaultValues: {
      transportation: transportationInit ?? "01",
      requestDate: requestDateInit,
      shipTo: shipToInit ?? "",
    },
    mode: "onBlur",
  });
  const { shipTo, requestDate } = watch();
  const canContinue = () => {
    return shipTo.length > 0;
  };
  const onSubmitHandler: SubmitHandler<FormFields> = (data) => {
    onSubmit({ ...data, cityId });
    trackGenerateQuote(data);
  };

  return (
    <QQSection blue>
      <div className={clsx(styles.container, "mt-30 mx-30 pb-30")}>
        <form onSubmit={handleSubmit(onSubmitHandler)}>
          <div className={clsx("d-flex align-items-center mb-30", styles.heading)}>
            <div className="flex-grow-1">
              <h5 className="font-size-20 text-dark font-weight-bold m-0">
                Shipping & Requested Date
              </h5>
            </div>
            <div className={clsx("font-size-16", styles.fileName)}>File: {fileName}</div>
          </div>
          <div className={clsx("d-flex", styles.formFields)}>
            <div className={styles.shippingMethod}>
              <Label>Shipping Method</Label>
              {transportRadios(register)}
            </div>
            <div className={styles.shipTo}>
              <ShipToTypeAhead
                labelText="Ship To"
                register={register}
                setValue={setValue}
                defaultShipToValue={shipTo}
                onCityIdChange={(value) => setCityId(value)}
              />
            </div>
            <div className={styles.requestedDeliveryDate}>
              <CalendarInput
                labelText="Requested Delivery Date"
                register={register}
                setValue={setValue}
                requestDate={requestDate}
              />
            </div>
            <div className={styles.generateQuoteButton}>
              <ToolTip
                placement="top"
                popOverText={"Please enter Ship To City and State"}
                enableTip={!canContinue()}
              >
                <span className="d-inline-block">
                  <Button
                    disabled={!canContinue()}
                    className={clsx({ [styles.pointerEvents]: !canContinue() })}
                    type="submit"
                  >
                    Generate Quote
                  </Button>
                </span>
              </ToolTip>
            </div>
          </div>
        </form>
      </div>
    </QQSection>
  );
};
