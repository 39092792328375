import React, { useEffect, useReducer } from "react";
import { FileType, FileUpload, Upload } from "./Upload";
import styles from "./QuotePage.module.scss";
import clsx from "clsx";
import { Summary } from "./Summary";
import { QuoteResults } from "./QuoteResults";
import { QuotePageFooter } from "./QuotePageFooter";
import {
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  SvgIcon,
  IconColors,
  PageCard,
} from "common/components";
import { clearResults, saveSelectedFile } from "./utils";
import {
  appState,
  showError,
  startLoading,
  stopLoading,
  useAppDispatch,
  useAppSelector,
} from "app";
import {
  createQuoteCategories,
  initialPageState,
  processNestedResults,
  quotePageReducer,
  resultsToSummary,
  sendQuoteRequest,
  sendQuoteSelections,
  setActiveStep,
  setQuoteDetails,
  QuoteView,
  UnnestedFile,
  moveToResults,
} from "./utils";
import { SelectedItem, ShipToFormValues } from "./QuoteResults/utils";
import { ErrorMessage, getErrorMessage } from "common/services";
import { useSaveUploadedFile } from "hooks";
import { datadogLogs } from "@datadog/browser-logs";

const isNestingEnabled = (fileType: FileType | null) => {
  if (fileType) {
    return fileType === FileType.Unnested;
  }
  return false;
};
const doNesting = async (
  uid: string,
  nesting: UnnestedFile,
  useStandardLength: boolean,
  shippingMethod: string,
  kerfWeight?: number,
  maxNestedBeamLength?: number,
  gap?: number,
  trimCut?: number,
) => {
  return await processNestedResults(uid, nesting, shippingMethod, {
    useStandardLength,
    kerfWeight,
    maxNestedBeamLength,
    gap,
    trimCut,
  });
};
const noFileChanges = (prev: FileUpload, current: FileUpload) =>
  prev.file?.name === current.file?.name && prev.fileType === current.fileType;

const canMoveToResults = (upload: FileUpload, quoteId: number | null, uid: string | null) =>
  (upload.file && upload.fileType === FileType.Unnested && quoteId && uid) ||
  (upload.fileType === FileType.Nested && quoteId);

export const QuotePage: React.FC = () => {
  const appDispatch = useAppDispatch();
  const { customer } = useAppSelector(appState);
  const [state, dispatch] = useReducer(quotePageReducer, initialPageState);
  const {
    error: uploadError,
    quoteId,
    uid,
    nestingUid,
    token,
    isLoading: isUploadLoading,
    resetError: resetUploadError,
    resetLastResult: resetLastUploadResult,
  } = useSaveUploadedFile(state.uploadedFile, customer);
  const tabHeadingStyle = (active: boolean, complete: boolean, inactive: boolean) =>
    clsx(
      "m-0 font-size-26 text-uppercase",
      { "text-inactive": inactive },
      { "text-primary": active },
      { "text-success-dark": complete },
    );
  const onFileSelected = async (file: FileUpload) => {
    if (uploadError) {
      datadogLogs.logger.error("[Quote Page] File Upload Error", {
        name: "File Upload Error",
        file: file,
      });
      resetUploadError();
    } else if (noFileChanges(state.uploadedFile, file)) {
      datadogLogs.logger.info("[Quote Page] File Upload: No changes", {
        name: "File Upload no change",
        file: state.uploadedFile,
      });
      dispatch(moveToResults(true));
    } else {
      datadogLogs.logger.info("[Quote Page] File Upload: Reset Last Upload file", {
        name: "File upload reset",
      });
      resetLastUploadResult();
    }
    datadogLogs.logger.info("[Quote Page] File Upload: Success", {
      name: "File Upload Success",
      file: state.uploadedFile,
    });
    dispatch(saveSelectedFile(file));
  };
  useEffect(() => {
    if (uploadError) {
      datadogLogs.logger.error("[Quote Page] File Upload Error", {
        name: "File Upload Error",
        location: "Quote Page Line 113",
      });
      appDispatch(showError({ ...getErrorMessage(uploadError.data.message) }));
    }
  }, [uploadError, appDispatch]);

  //triggers moving to results page after upload assuming all things are in order
  useEffect(() => {
    if (canMoveToResults(state.uploadedFile, quoteId, uid) && !isUploadLoading && !uploadError) {
      datadogLogs.logger.info("[Quote Page] File Upload: Results page", {
        name: "Can move to Results page",
      });
      dispatch(moveToResults(true));
      dispatch(clearResults());
    }
  }, [state.uploadedFile, quoteId, uid, isUploadLoading, uploadError]);

  useEffect(() => {
    if (isUploadLoading) {
      datadogLogs.logger.info("[Quote Page] Modal: Uploading File START", {
        name: "START: Uploading File Modal view",
      });
      appDispatch(startLoading({ message: "Uploading File" }));
    } else {
      datadogLogs.logger.info("[Quote Page] Modal:Uploading File END", {
        name: "END: Uploading File Modal view",
      });
      appDispatch(stopLoading());
    }
  }, [appDispatch, isUploadLoading]);
  const onQuoteSubmit = async (values: ShipToFormValues) => {
    let error: ErrorMessage | null = null;
    if (uid) {
      datadogLogs.logger.info(
        "[Quote Page] Modal: Calculating Options and Generating Your Quote START",
        {
          name: "START: Calculating options modal view",
          details: "UID: " + uid,
        },
      );
      appDispatch(
        startLoading({
          message: "Calculating Options and Generating Your Quote",
          messageDetail:
            "Hang tight, depending on file complexity results may take up to 5 minutes or longer",
        }),
      );
      if (isNestingEnabled(state.uploadedFile.fileType)) {
        datadogLogs.logger.info("[Quote Page] Nesting: GET nesting results", {
          name: "START: Calculating options modal view",
          details: "Data" + values,
        });
        error = await doNesting(
          uid,
          { mulesoftToken: token, uid: nestingUid },
          values.useStandardLength,
          values.transportation,
          values.kerfWeight,
          values.maxNestedBeamLength,
          values.gap,
          values.trimCut,
        );
      }
      if (!error) {
        datadogLogs.logger.info("[Quote Page] START: Send quote request", {
          name: "START: Send quote request",
          details: "Data: " + values,
        });
        const result = await sendQuoteRequest(uid, values);
        datadogLogs.logger.info("[Quote Page] END: Send quote request", {
          name: "END: Send quote request",
          details: "Data Result: " + result,
        });
        error = result.error;
        if (!error) {
          datadogLogs.logger.error("[Quote Page] START: setQuoteDetails", {
            name: "START: setQuoteDetails",
            details: "Data: " + values,
          });
          dispatch(
            setQuoteDetails({
              ...createQuoteCategories(result.data, values.requestDate),
              quoteDetails: result.data,
              ...values,
            }),
          );
          datadogLogs.logger.info("[Quote Page] END: setQuoteDetails", {
            name: "END: setQuoteDetails",
            details: "Data: " + values,
          });
        }
      }
      datadogLogs.logger.info(
        "[Quote Page] Modal: Calculating Options and Generating Your Quote END",
        {
          name: "END: Calculating options modal view",
        },
      );
      appDispatch(stopLoading());
      if (error) {
        datadogLogs.logger.error(
          "[Quote Page] Results: Calculating Options and Generating Your Quote ERROR",
          {
            name: "ERROR: Calculating options modal view",
            error: error,
          },
        );
        appDispatch(showError({ ...error }));
      }
    }
  };
  const continueToSummary = async (itemsSelected: SelectedItem[]) => {
    if (uid) {
      datadogLogs.logger.info("[Quote Page] START: Continue to Summary: Saving Selections", {
        name: "START: Continue to Summary",
        details: "UID: " + uid,
      });
      appDispatch(
        startLoading({
          message: "Saving Your Selections",
        }),
      );
      datadogLogs.logger.info("[Quote Page] START: Continue to Summary: Saving Selections", {
        name: "START: Continue to Summary",
        details: "UID: " + uid,
      });
      const error = await sendQuoteSelections(uid, itemsSelected);

      appDispatch(stopLoading());
      if (!error) {
        datadogLogs.logger.info("[Quote Page] END: Continue to Summary: Saving Selections", {
          name: "END: Continue to Summary",
          details: "UID: " + uid,
        });
        dispatch(resultsToSummary(itemsSelected));
      } else {
        datadogLogs.logger.error("[Quote Page] ERROR: Continue to Summary: Saving Selections", {
          name: "ERROR: Continue to Summary",
          details: "UID: " + uid,
        });
        appDispatch(showError({ ...error }));
      }
    }
  };

  return (
    <div className={clsx(styles.container, { "mb-70": state.activeStep === QuoteView.Results })}>
      <PageCard className="mx-auto">
        <TabList selectedTabIndex={state.activeStep}>
          <Tab onClick={() => dispatch(setActiveStep(QuoteView.Upload))}>
            {(active, complete, inactive) => (
              <h2 className={tabHeadingStyle(active, complete, inactive)}>
                {complete ? (
                  <SvgIcon
                    name="check-complete"
                    attributes={{
                      width: "17px",
                      height: "17px",

                      fill: IconColors.SUCCESS,
                    }}
                    className="mr-12"
                  />
                ) : (
                  <SvgIcon
                    name="cloud"
                    attributes={{
                      width: "22px",
                      height: "16px",
                      viewBox: "0 3 26.4 13",
                      fill: inactive ? IconColors.LIGHT : IconColors.PRIMARY,
                    }}
                    className="mr-12"
                  />
                )}
                Upload
              </h2>
            )}
          </Tab>
          <Tab onClick={() => dispatch(moveToResults())}>
            {(active, complete, inactive) => (
              <h2 className={tabHeadingStyle(active, complete, inactive)}>
                {complete ? (
                  <SvgIcon
                    name="check-complete"
                    attributes={{
                      width: "17px",
                      height: "17px",
                      fill: IconColors.SUCCESS,
                    }}
                    className="mr-12"
                  />
                ) : (
                  <SvgIcon
                    name="gear"
                    attributes={{
                      width: "17px",
                      height: "17px",
                      viewBox: "6 5 19 20",
                      fill: inactive ? IconColors.LIGHT : IconColors.PRIMARY,
                    }}
                    className="mr-12"
                  />
                )}
                Quote
              </h2>
            )}
          </Tab>
          <Tab>
            {(active, complete, inactive) => (
              <h2 className={tabHeadingStyle(active, complete, inactive)}>
                {complete ? (
                  <SvgIcon
                    name="check-complete"
                    attributes={{
                      width: "17px",
                      height: "17px",
                      fill: IconColors.SUCCESS,
                    }}
                    className="mr-12"
                  />
                ) : (
                  <SvgIcon
                    name="clipboard"
                    attributes={{
                      width: "17px",
                      height: "21px",
                      viewBox: "5 7 21 16",
                      fill: inactive ? IconColors.LIGHT : IconColors.PRIMARY,
                    }}
                    className="mr-12"
                  />
                )}
                Finish
              </h2>
            )}
          </Tab>
        </TabList>
        <TabPanels>
          <TabPanel selectedTabIndex={state.activeStep}>
            <Upload
              fileType={state.uploadedFile.fileType}
              uploadFile={state.uploadedFile.file}
              onSubmit={onFileSelected}
            />
          </TabPanel>
          <TabPanel selectedTabIndex={state.activeStep}>
            <QuoteResults
              key={state.resultsKey}
              uid={uid!}
              uploadedFileName={state.uploadedFile.file?.name ?? ""}
              enableNesting={isNestingEnabled(state.uploadedFile.fileType)}
              onQuoteSubmit={onQuoteSubmit}
              onContinue={continueToSummary}
              {...state.quoteResultProps}
            />
          </TabPanel>
          <TabPanel selectedTabIndex={state.activeStep}>
            <Summary
              file={state.uploadedFile.file}
              uid={uid!}
              quoteId={quoteId!}
              requestDate={state.quoteResultProps.requestDate}
              quoteDetails={state.quoteResultProps.quoteDetails}
              selectedItems={state.quoteResultProps.itemsSelected}
              isNesting={isNestingEnabled(state.uploadedFile.fileType)}
            />
          </TabPanel>
        </TabPanels>
      </PageCard>
      <QuotePageFooter show={state.activeStep === QuoteView.Upload} />
    </div>
  );
};
