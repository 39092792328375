import React from "react";
import { SvgIcon } from ".";

type IconCheckBoxProps = {
  checked: boolean;
  className?: string;
};

export const IconCheckBox: React.FC<IconCheckBoxProps> = (props) =>
  props.checked ? (
    <SvgIcon name="checkbox-on" className={props.className} />
  ) : (
    <SvgIcon
      name="checkbox-off"
      attributes={{ width: "12px", height: "12px" }}
      className={props.className}
    />
  );
