import React from "react";
import { ReactComponent as cloud } from "assets/redesign_icons/ico_cloud_link.svg";
import { ReactComponent as gear } from "assets/redesign_icons/ico_gear_link.svg";
import { ReactComponent as checkComplete } from "assets/redesign_icons/ico_alert_check_grn.svg";
import { ReactComponent as clipboard } from "assets/redesign_icons/ico_clipboard_link.svg";
import { ReactComponent as xlsxIcon } from "assets/redesign_icons/ico_xls.svg";
import { ReactComponent as loadingSpinner } from "assets/redesign_icons/spinner_dot.svg";
import { ReactComponent as warning } from "assets/redesign_icons/ico_alert_warning.svg";
import { ReactComponent as error } from "assets/redesign_icons/ico_alert_error.svg";
import { ReactComponent as plusIcon } from "assets/redesign_icons/ico_plus_link.svg";
import { ReactComponent as minus } from "assets/redesign_icons/ico_minus_link.svg";
import { ReactComponent as checkboxOff } from "assets/redesign_icons/form_check_qq_default.svg";
import { ReactComponent as checkboxOn } from "assets/redesign_icons/form_check_qq_selected.svg";
import { ReactComponent as infoAlert } from "assets/redesign_icons/ico_alert_info.svg";
import { ReactComponent as sortAscendingArrow } from "assets/redesign_icons/Ascending.svg";
import { ReactComponent as sortDescendingArrow } from "assets/redesign_icons/Descending.svg";
import { ReactComponent as sortDefaultArrow } from "assets/redesign_icons/DefaultSort.svg";
import { ReactComponent as binoculars } from "assets/redesign_icons/Binoculars.svg";
import { ReactComponent as download } from "assets/redesign_icons/download.svg";
import { ReactComponent as downloadWhite } from "assets/redesign_icons/download-white.svg";
import { ReactComponent as search } from "assets/redesign_icons/search.svg";
import { ReactComponent as overlay } from "assets/redesign_icons/overlay.svg";
import { ReactComponent as home } from "assets/redesign_icons/home.svg";
import { ReactComponent as arrowDown } from "assets/redesign_icons/arrow_down.svg";
import { ReactComponent as email } from "assets/redesign_icons/email.svg";
import { ReactComponent as clearInput } from "assets/redesign_icons/ico_form_clear_link.svg";
import { ReactComponent as filterSolid } from "assets/redesign_icons/filter-solid.svg";
import { ReactComponent as filterOutline } from "assets/redesign_icons/filter-outline.svg";
import { ReactComponent as errorWhite } from "assets/redesign_icons/ico_alert_error_white.svg";

export type SvgComponentType = React.FunctionComponent<
  React.SVGProps<SVGSVGElement> & {
    title?: string | undefined;
  }
>;

export enum IconColors {
  PRIMARY = "#0578BE",
  LIGHT = "#BFC9D0",
  SUCCESS = "#31AF19",
  WARNING = "#E5A812",
  DARKGREEN = "#31AF19",
  GERDAUBLUE = "#40668C",
  NAVYBLUE = "#192D38",
  WHITE = "#FFFFFF",
}

const IconMap = new Map<string, SvgComponentType>([
  ["cloud", cloud],
  ["gear", gear],
  ["check-complete", checkComplete],
  ["clipboard", clipboard],
  ["xlsx-document", xlsxIcon],
  ["loading-spinner", loadingSpinner],
  ["warning", warning],
  ["error", error],
  ["plus", plusIcon],
  ["minus", minus],
  ["checkbox-off", checkboxOff],
  ["checkbox-on", checkboxOn],
  ["info-alert", infoAlert],
  ["sort-ascending-arrow", sortAscendingArrow],
  ["sort-descending-arrow", sortDescendingArrow],
  ["sort-default-arrow", sortDefaultArrow],
  ["binoculars", binoculars],
  ["download", download],
  ["search", search],
  ["overlay", overlay],
  ["home", home],
  ["arrow-down", arrowDown],
  ["email", email],
  ["clear", clearInput],
  ["download-white", downloadWhite],
  ["filterSolid", filterSolid],
  ["filterOutline", filterOutline],
  ["errorWhite", errorWhite],
]);

type SvgIconProps = {
  attributes?: React.SVGProps<SVGSVGElement>;
  name: string;
  className?: string;
  onClick?: () => void;
};

export const SvgIcon: React.FunctionComponent<SvgIconProps> = ({
  attributes,
  name,
  className,
  onClick = () => {},
}) => {
  const IconComponent = IconMap.get(name);

  if (IconComponent) {
    if (attributes) {
      return <IconComponent {...{ ...attributes, onClick, className }} />;
    }
    return <IconComponent onClick={onClick} className={className} />;
  }
  return null;
};
