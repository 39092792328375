import clsx from "clsx";
import { IconColors } from "common/components";
import React, { useState } from "react";
import {
  getClosestPlantId,
  getItemSelectedQty,
  Item,
  ItemPlant,
  NormalizedObjects,
  PlantOption,
  SelectedItem,
  SelectedOption,
  filterOptions,
} from "../utils";
import { ItemHeading, ItemMillOptionRow } from ".";
import { LocaleString } from "common/services";
import styles from "./MillOptionsSection.module.scss";
import { MillOptionsSectionHeader } from "./MillOptionsSectionHeader";

interface MillOptionsSectionProps {
  item: Item;
  showDetail: boolean;
  iconColor: IconColors;
  itemPlants: NormalizedObjects<ItemPlant>;
  plantOptions: NormalizedObjects<PlantOption>;
  requestDate: string;
  selectedOptionIds: SelectedItem[];
  selectedPlants: string;
  filterAvailability: string;
  filterQuantity: string;
  shipTo: string;
  shippingMethod: string;
  onSelectionChange?: (option: SelectedOption) => void;
  onCutListClick?: (item: Item, quantitySelected: number) => void;
}
const isFirstOption = (index: number) => index === 0;

export const MillOptionsSection: React.FunctionComponent<MillOptionsSectionProps> = ({
  item,
  showDetail,
  iconColor,
  itemPlants,
  plantOptions,
  requestDate,
  selectedOptionIds,
  selectedPlants,
  filterAvailability,
  filterQuantity,
  shipTo,
  shippingMethod,
  onSelectionChange = () => {},
  onCutListClick = () => {},
}) => {
  const filterOption = filterOptions(
    item,
    plantOptions,
    itemPlants,
    filterQuantity,
    filterAvailability,
    selectedPlants,
    requestDate,
  );

  const [quantitySelected, setQuantitySelected] = useState<number>(
    getItemSelectedQty(item, selectedOptionIds, plantOptions),
  );
  const handleSelectionChange = (option: SelectedOption) => {
    if (option.isSelected) {
      setQuantitySelected(quantitySelected + option.orderQuantity);
    } else {
      setQuantitySelected(quantitySelected - option.orderQuantity);
    }
    onSelectionChange(option);
  };
  const isSelected = (optionId: string) =>
    selectedOptionIds.map((id) => id.optionUid).includes(optionId);

  return (
    <>
      <ItemHeading
        item={filterOption}
        iconName="check-complete"
        attributes={{ width: "16px", height: "16px", fill: iconColor }}
        quantitySelected={quantitySelected}
        onCutListClick={onCutListClick}
        defaultPlant={getClosestPlantId(item, itemPlants)}
        requestDate={requestDate}
        shipTo={shipTo}
      />

      <div className={clsx({ "d-block": showDetail, "d-none": !showDetail })}>
        <div className={clsx(styles.sections, "d-flex flex-wrap")}>
          {item.options.map((plantId) => (
            <React.Fragment key={plantId}>
              {itemPlants[plantId].options.length > 0 &&
              (selectedPlants === "" || selectedPlants === itemPlants[plantId].plantId) &&
              filterOption.options.length > 0 ? (
                <div className={styles.table}>
                  <MillOptionsSectionHeader
                    plantId={plantId}
                    item={item}
                    itemPlants={itemPlants}
                    shipTo={shipTo}
                    shippingMethod={shippingMethod}
                  />
                  <table className="table">
                    <tbody>
                      {itemPlants[plantId].options.map((optionId, index) => (
                        <React.Fragment key={optionId}>
                          {isFirstOption(index) && (
                            <tr className="bg-secondary text-white font-size-12">
                              <th>Order Qty</th>
                              <th>
                                Pieces/Bundle{" "}
                                <span className="font-size-10">
                                  (
                                  {`${plantOptions[optionId].standardBundleWeight.toLocaleString(
                                    LocaleString,
                                  )} LB/BDL`}
                                  )
                                </span>
                              </th>
                              <th>Availability</th>
                            </tr>
                          )}
                          <ItemMillOptionRow
                            option={plantOptions[optionId]}
                            pieces={item.pieces}
                            requestDate={requestDate}
                            defaultChecked={isSelected(optionId)}
                            onSelectionChange={handleSelectionChange}
                            filterAvailability={filterAvailability}
                            filterQuantity={filterQuantity}
                          />
                        </React.Fragment>
                      ))}
                    </tbody>
                  </table>
                </div>
              ) : null}
            </React.Fragment>
          ))}
        </div>
      </div>
    </>
  );
};
