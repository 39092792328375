import clsx from "clsx";
import React from "react";
import styles from "./QuotePageFooter.module.scss";

interface QuotePageFooterProps {
  show: boolean;
}

export const QuotePageFooter: React.FC<QuotePageFooterProps> = ({ show = false }) => {
  return show ? (
    <div className={clsx("mx-auto mt-30 mb-30 font-size-12 text-center", styles.disclaimer)}>
      <span>Important:</span> Stock is subject to availability and prior sale. Rollings may
      change/close without prior notice.
    </div>
  ) : null;
};
