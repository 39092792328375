import clsx from "clsx";
import { Label, QQSection } from "common/components";
import React from "react";
import styles from "./NestingPrefs.module.scss";
import { PrefRangeValue } from ".";

interface NestingPrepsProps {
  useStandardLength?: boolean;
  kerfWeight?: number;
  maxNestedBeamLength?: number;
  gap?: number;
  trimCut?: number;
  onKerfChange?: (value: number) => void;
  onBeamLengthChange?: (value: number) => void;
  onGapChange?: (value: number) => void;
  onTrimCutChange?: (value: number) => void;
  onUseStandardLengthChange?: (value: boolean) => void;
}

export const NestingPrefs: React.FunctionComponent<NestingPrepsProps> = ({
  useStandardLength = false,
  kerfWeight = 0,
  maxNestedBeamLength = 0,
  gap = 0,
  trimCut = 0,
  onKerfChange = () => {},
  onBeamLengthChange = () => {},
  onGapChange = () => {},
  onTrimCutChange = () => {},
  onUseStandardLengthChange = () => {},
}) => {
  const beamLengthRange = {
    min: 0,
    max: 75,
    step: useStandardLength ? 5 : 1,
  };
  const gapRange = {
    min: 0,
    max: 5,
    step: 1,
  };
  const kerfWidthRange = {
    min: 0,
    max: 1,
    step: 1 / 16,
  };
  const trimCutRange = {
    min: 0,
    max: 1,
    step: 1 / 16,
  };
  const handleUseStandardLengthChange = () => {
    onUseStandardLengthChange(!useStandardLength);
  };

  return (
    <QQSection blue>
      <div className={clsx("mt-30 ml-30 pb-18 mr-18", styles.container)}>
        <h5 className="m-0 mb-18 font-weight-bold">Uncombined Preferences</h5>
        <div className="d-flex">
          <div className="flex-fill">
            <div className={styles.sawPrefs}>
              <PrefRangeValue
                rangeStepMinMax={trimCutRange}
                headingText="Saw Preferences"
                labelText="Trim Cut"
                defaultValue={trimCut}
                fractionValue={true}
                onChange={onTrimCutChange}
              />
              <PrefRangeValue
                rangeStepMinMax={kerfWidthRange}
                labelText="Kerf Width"
                defaultValue={kerfWeight}
                fractionValue={true}
                onChange={onKerfChange}
              />
            </div>
          </div>
          <div className="flex-fill">
            <div className={styles.yardPrefs}>
              <PrefRangeValue
                rangeStepMinMax={gapRange}
                headingText="Yard Preferences"
                labelText="Distinct Gap Between Lengths"
                defaultValue={gap}
                onChange={onGapChange}
              />
              <PrefRangeValue
                rangeStepMinMax={beamLengthRange}
                labelText="Maximum Beam Length"
                defaultValue={maxNestedBeamLength}
                onChange={onBeamLengthChange}
              />
              <div className={clsx("custom-control custom-switch mt-24 font-size-12")}>
                <input
                  className="custom-control-input"
                  type="checkbox"
                  checked={useStandardLength}
                  onChange={handleUseStandardLengthChange}
                  id="standardLength"
                />
                <Label className="custom-control-label pt-6" htmlFor="standardLength">
                  Standard Lengths Only
                  <span className={styles.parenthesesStyle}> (Beams in Increments of 5') </span>
                </Label>
              </div>
            </div>
          </div>
          <div>
            <div className={clsx("bg-white p-12", styles.disclaimer)}>
              <h6 className="m-0 mb-6 font-size-16 font-weight-bold text-secondary">
                This is a "Beta" release
              </h6>
              <Label>There are some known limitations.</Label>
              <ul className="m-0 mt-9 font-size-12">
                <li>
                  Sequences are always combined to minimize loss, this may not align with your
                  production process.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </QQSection>
  );
};
