import { configureStore } from "@reduxjs/toolkit";
import { appReducer } from "app";
import { EgerdauApi, QuickQuoteApi } from "common/services";

export const appStore = configureStore({
  reducer: {
    app: appReducer,
    [QuickQuoteApi.reducerPath]: QuickQuoteApi.reducer,
    [EgerdauApi.reducerPath]: EgerdauApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat([QuickQuoteApi.middleware, EgerdauApi.middleware]),
});

export type RootState = ReturnType<typeof appStore.getState>;
export type AppDispatch = typeof appStore.dispatch;
