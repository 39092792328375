import React from "react";
import { IconColors, QQLink, SvgIcon } from "common/components";
import styles from "./ItemHeading.module.scss";
import clsx from "clsx";
import { calculateDropPercent, formatItemDescription, Item } from "../utils";
import { ItemHeadingAside } from "./ItemHeadingAside";

interface ItemHeadingProps {
  item: Item;
  iconName: string;
  attributes: React.SVGProps<SVGSVGElement>;
  requestDate: string;
  defaultPlant: number;
  quantitySelected?: number;
  shipTo: string;
  onCutListClick?: (item: Item, quantitySelected: number) => void;
}

export const ItemHeading: React.FunctionComponent<ItemHeadingProps> = ({
  item,
  iconName,
  attributes,
  requestDate,
  defaultPlant,
  quantitySelected = 0,
  shipTo = "TAMPA, FL",
  onCutListClick = () => {},
}) => {
  const hasCutList = () => item.cut_list !== null && item.cut_list.length > 0;
  const dropPercent = () => calculateDropPercent(item).toFixed(2);

  return (
    <h6
      className={clsx("font-size-16 pl-2 font-weight-bold text-secondary", styles.productHeading)}
      hidden={item.options.length === 0}
    >
      <SvgIcon name={iconName} attributes={attributes} className="mr-6" />
      <span className="mr-5">{formatItemDescription(item)}</span>
      <span className={clsx(styles.openSans, "font-size-12")}>
        <ItemHeadingAside>
          {item.pieces} Pieces Requested / {quantitySelected} Pieces Selected
        </ItemHeadingAside>
        {hasCutList() && (
          <>
            <span className="ml-6 text-light">{`${dropPercent()}% Drop`}</span>
            <QQLink
              className="ml-6 font-size-12"
              link
              onClick={() => onCutListClick(item, quantitySelected)}
            >
              <SvgIcon
                name="overlay"
                attributes={{ width: "10px", height: "10px", fill: IconColors.PRIMARY }}
                className="mr-3"
              />
              Cut List{" "}
            </QQLink>
          </>
        )}
      </span>
    </h6>
  );
};
