import { datadogLogs } from "@datadog/browser-logs";

const getLocalConfig =
  (
    get: (key: string) => string | null,
    parse: (
      value: any,
      replacer?: (this: any, key: string, value: any) => any,
      space?: string | number,
    ) => string,
  ) =>
  (key: string): any => {
    try {
      const localData = get(key);
      return localData ? parse(localData) : null;
    } catch (error) {
      datadogLogs.logger.error("[Local Storage]: Error Get local Config", {
        name: "Error : Get local Config",
        details: error,
      });
      console.error(error);
    }
    return null;
  };
const setLocalConfig =
  (
    set: (key: string, value: string) => void,
    format: (
      value: any,
      replacer?: (this: any, key: string, value: any) => any,
      space?: string | number,
    ) => string,
  ) =>
  (key: string, value: any) => {
    try {
      set(key, format(value));
    } catch (error) {
      datadogLogs.logger.error("[Local Storage]: Error Set Local Config", {
        name: "Error : Set local Config",
        details: error,
      });
      console.error(error);
    }
  };
export type SetLocalFn = (key: string, value: any) => void;

export const getLocalData = getLocalConfig(localStorage.getItem.bind(localStorage), JSON.parse);
export const getLocal = <T>(key: string) => getLocalData(key) as T;
export const setLocalData = setLocalConfig(localStorage.setItem.bind(localStorage), JSON.stringify);
