import clsx from "clsx";
import { QQSection } from "common/components";
import React from "react";
import { calculateDropPercent, combineCutListPieces, formatItemDescription, Item } from "../utils";
import { CutListTableRow } from "./CutListRow";
import styles from "./CutListTable.module.scss";

interface CutListTableProps {
  item: Item | null;
  quantitySelected?: number;
}

export const CutListTableSummary: React.FunctionComponent<CutListTableProps> = ({
  item,
  quantitySelected = 0,
}) => {
  const dropPercent = () => calculateDropPercent(item).toFixed(2);
  return item ? (
    <div className={styles.container}>
      <table className="table table-bordered">
        <thead>
          <tr className="bg-secondary text-white mb-3 font-size-12">
            <th scope="col">
              {formatItemDescription(item)} {"  "}
              <span className={clsx(styles.openSans, "d-inline-block ml-12 font-weight-normal")}>
                <span className="mr-3">(</span>
                {`${item.pieces} Pieces Requested / ${quantitySelected} Pieces Selected`}
                <span className="ml-3">)</span> {"  "}
                {`${dropPercent()}%`} Drop
              </span>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <QQSection className="p-24" white bottomSection>
              <span className="m-5 text-secondary font-size-16">{`${quantitySelected} Pieces Selected`}</span>

              <table className="table">
                <tbody className="font-size-12">
                  {item.cut_list &&
                    combineCutListPieces(item.cut_list).map((option) =>
                      option.pieces.map(
                        (piece: { length: number; quantity: number }, index: number) => (
                          <CutListTableRow
                            key={index}
                            length={piece.length}
                            quantity={piece.quantity}
                            wastePercent={option.waste_pct}
                            pieceCount={option.piecesCount}
                            rowSpan={option.rowSpan}
                            index={index}
                            option={option}
                            millMark={option.millMark}
                          ></CutListTableRow>
                        ),
                      ),
                    )}
                </tbody>
              </table>
            </QQSection>
          </tr>
        </tbody>
      </table>
    </div>
  ) : null;
};
