import URLSearchParams from "@ungap/url-search-params";
import { AppEndPoints, UrlParams } from "./Types";

enum Env {
  LOCAL,
  DEV,
  QA,
  PPRD,
  PROD,
  UNKNOWN,
}

type EnvString = keyof typeof Env;

export const getEnv = (): EnvString => {
  let env: EnvString = "DEV";
  switch (window?.location.hostname) {
    case "dev-sales-quick-quote-web.gerdaugln.tech":
      env = "DEV";
      break;
    case "qa-sales-quick-quote-web.gerdaugln.tech":
      env = "QA";
      break;
    case "pre-prod-sales-quick-quote-web.gerdaugln.tech":
      env = "PPRD";
      break;
    case "quickquote.egerdau.com":
      env = "PROD";
      break;
    case "localhost":
      env = "LOCAL";
      break;
    default:
      env = "UNKNOWN";
  }
  return env;
};

const skipLoadingProfile =
  (getEnvFn: typeof getEnv) =>
  (...envs: EnvString[]) => {
    const env = getEnvFn();
    return envs.includes(env);
  };

export const canSkipLoading = skipLoadingProfile(getEnv);

export const getUrlWithQuery = (endPoint: string, params: UrlParams) =>
  `${endPoint}?${new URLSearchParams(params).toString()}`;

export const pluralize = (count: number, word: string, suffix: string = "s") =>
  `${count} ${word}${count === 1 ? "" : suffix}`;

export const pipeAsync =
  (...funcs: any[]) =>
  <T, V>(initialValue: T): V =>
    funcs.reduce((chain, func) => chain.then(func), Promise.resolve(initialValue));
export const pipe =
  (...funcs: any[]) =>
  <T, V>(initialValue: T): V =>
    funcs.reduce((acc, func) => func(acc), initialValue);

export const getEndpoints = (endPoints: Map<string, AppEndPoints>) => {
  let mappedEndPoints = endPoints.get(window?.location.hostname);
  if (!mappedEndPoints) {
    mappedEndPoints = {
      api: "",
      egerdauBaseUrl: "",
      egerdauAuth: "",
      egerdauCustomer: "",
      egerdauToken: "",
      egerdauCallback: "",
      muleSoftPricing: "",
    };
  }
  return mappedEndPoints;
};
