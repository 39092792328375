import { QuoteUploadData } from "./types";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
export interface QuoteLookupState {
  quoteDetails?: QuoteUploadData[];
  hasError: boolean;
  errorMessage?: string;
  agedPreset: string;
  searchValue: string;
}
export interface LookupSearchValues {
  agedPreset: string;
  searchValue: string;
}
export const AgedPresetDefault = "Past 30 Days";
export const initialState: QuoteLookupState = {
  quoteDetails: undefined,
  hasError: false,
  errorMessage: undefined,
  agedPreset: AgedPresetDefault,
  searchValue: "",
};

export const quoteLookupSlice = createSlice({
  name: "quoteLookup",
  initialState,
  reducers: {
    lookupError: (state: QuoteLookupState, action: PayloadAction<string>) => {
      state.errorMessage = action.payload;
      state.hasError = true;
      state.quoteDetails = undefined;
    },
    setQuoteDetails: (state: QuoteLookupState, action: PayloadAction<QuoteUploadData[]>) => {
      state.quoteDetails = action.payload;
      state.hasError = false;
    },
    setSearchValue: (state: QuoteLookupState, action: PayloadAction<string>) => {
      state.searchValue = action.payload;
    },
    setAgedPreset: (state: QuoteLookupState, action: PayloadAction<string>) => {
      state.agedPreset = action.payload;
    },
    setSearchValues: (state: QuoteLookupState, action: PayloadAction<LookupSearchValues>) => {
      state.agedPreset = action.payload.agedPreset;
      state.searchValue = action.payload.searchValue;
    },
  },
});
export const { lookupError, setQuoteDetails, setSearchValue, setAgedPreset, setSearchValues } =
  quoteLookupSlice.actions;
export const quoteLookupReducer = quoteLookupSlice.reducer;
