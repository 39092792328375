import clsx from "clsx";
import React from "react";
import { Range, getTrackBackground } from "react-range";
import { Label } from "common/components";
import styles from "./PrefRangeValue.module.scss";
import { calculateFraction } from "../utils";

interface PrefRangeValueProps {
  rangeStepMinMax: { step: number; min: number; max: number };
  defaultValue?: number;
  headingText?: string;
  labelText?: string;
  fractionDigits?: number;
  fractionValue?: boolean;
  onChange?: (value: number) => void;
}

export const PrefRangeValue: React.FunctionComponent<PrefRangeValueProps> = ({
  rangeStepMinMax,
  defaultValue = 0,
  headingText,
  labelText,
  fractionDigits,
  fractionValue,
  onChange = () => {},
}) => {
  const { min, max } = rangeStepMinMax;
  const InchOrFeet = fractionValue ? '"' : "'";
  const displayValue = fractionValue
    ? `${calculateFraction(defaultValue)}${InchOrFeet}`
    : `${defaultValue.toFixed(fractionDigits)}${InchOrFeet}`;

  return (
    <>
      <h6 className={clsx("mb-12 font-size-16 font-weight-bold text-secondary", styles.heading)}>
        {headingText}
      </h6>
      <Label htmlFor="kerf-width">
        {labelText}
        <span className={clsx("ml-5 px-4 py-2 font-size-10", styles.sliderValueDisplay)}>
          {displayValue}
        </span>
      </Label>
      <Range
        {...rangeStepMinMax}
        values={[defaultValue]}
        onChange={(values) => onChange(values[0])}
        renderTrack={({ props, children }) => (
          <div
            onMouseDown={props.onMouseDown}
            onTouchStart={props.onTouchStart}
            className={clsx("d-flex w-100", styles.renderTrack)}
            style={{
              ...props.style,
            }}
          >
            <div
              ref={props.ref}
              className={clsx("w-100 rounded mb-5 mt-10 align-self-center", styles.sliderBox)}
              style={{
                background: getTrackBackground({
                  values: [defaultValue],
                  colors: ["#31af19", "#f5f8fa"],
                  min: min,
                  max: max,
                }),
              }}
            >
              {children}
            </div>
          </div>
        )}
        renderThumb={({ props }) => (
          <div
            className={clsx(
              "d-flex align-items-center justify-content-center bg-white",
              styles.sliderBoxThumb,
            )}
            {...props}
          >
            <div className={clsx("position-absolute font-size-10 mt-2", styles.sliderBoxValue)}>
              {fractionValue ? "" : displayValue}
            </div>
            <div className={clsx("bg-white", styles.sliderBoxPipe)} />
          </div>
        )}
      />
      <div className={clsx("d-flex font-size-10", styles.sliderLabel)}>
        <span className="flex-fill ml-n6">{`${min}${InchOrFeet}`}</span>
        {fractionValue ? <span>&nbsp;• 1/8 • 1/4 • 3/8 • 1/2 • 5/8 • 3/4 • 7/8 •&nbsp;</span> : ""}
        <span className="flex-fill text-right">{`${max}${InchOrFeet}`} </span>
      </div>
    </>
  );
};
