import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { RootState } from "app";
import { AuthUrl, CallbackUrl, ClientId, ClientSecret, EgerdauUrl } from "./Constants";
import { getUrlWithQuery } from "./Util";
import { datadogLogs } from "@datadog/browser-logs";

export interface EgerdauToken {
  access_token: string;
  token_type: string;
  refresh_token: string;
  expires_in: number;
  scope: string;
}
export interface EgerdauCookie {
  soldToUnit: string;
  customerEmailId: string;
}
interface IsoCode {
  name?: string;
  active?: boolean;
  isocode?: string;
}

interface Currency extends IsoCode {
  symbol?: string;
}

interface Language extends IsoCode {
  nativeName?: string;
}

interface ShipToAddress {
  id?: string;
  name?: string;
  address?: string;
  description?: string;
}
export interface EgerdauCustomer {
  uid?: string;
  name?: string;
  type?: string;
  shipTo?: ShipToAddress[];
  currency?: Currency;
  language?: Language;
  lastName?: string;
  soldToId?: string;
  usertype?: string;
  firstName?: string;
  displayUid?: string;
  soldToName?: string;
}
const EgerdauAuthQueryParams: Record<string, string> = {
  response_type: "code",
  client_id: ClientId,
  client_secret: ClientSecret,
  redirect_uri: CallbackUrl,
  scopes: "basic",
};
const TokenQueryParams: Record<string, string> = {
  grant_type: "authorization_code",
  client_id: ClientId,
  client_secret: ClientSecret,
  redirect_uri: CallbackUrl,
  code: "",
};
export const EgerdauAuthUrl = getUrlWithQuery(AuthUrl, EgerdauAuthQueryParams);
const redirect = (url: string) => (window.location.href = url);
export const redirectToEgerdau = () => redirect(EgerdauAuthUrl);

export const EgerdauApi = createApi({
  reducerPath: "egerdauApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${EgerdauUrl}/`,
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as RootState).app.accessToken;
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    },
    fetchFn: async (input, init) => {
      try {
        const response = await fetch(input, init);
        const responseClone = response.clone();
        if (!response.ok) {
          const error = await responseClone.json();
          datadogLogs.logger.error("[eGerdau API call error]: Exception during API call:", {
            error: error,
          });
        }
        return response;
      } catch (error) {
        datadogLogs.logger.error(
          "[eGerdau API call error]: Unexpected exception during API call:",
          {
            error: error,
          },
        );
        throw error;
      }
    },
  }),
  endpoints: (build) => ({
    getEgerdauToken: build.mutation<EgerdauToken, string>({
      query: (code) => ({
        url: "authorizationserver/oauth/token",
        params: { ...TokenQueryParams, code },
        method: "POST",
      }),
    }),
    getEgerdauCustomer: build.query<EgerdauCustomer, void>({
      query: () => ({
        url: "rest/v2/egerdauNA/users/current",
      }),
    }),
  }),
});

export const { useGetEgerdauTokenMutation, useGetEgerdauCustomerQuery } = EgerdauApi;
