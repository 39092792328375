import { datadogLogs } from "@datadog/browser-logs";
import { showError, useAppDispatch } from "app";
import clsx from "clsx";
import { IconColors, SvgIcon } from "common/components";
import { ErrorMap, ErrorType } from "common/services";
import React from "react";
import { useDropzone } from "react-dropzone";
import styles from "./FileDropArea.module.scss";
import { trackUploadFile } from "common/tagManagerService";
const invalidExtError = ErrorMap.get(ErrorType.UploadFormatInvalid);
const uploadMultipleError = ErrorMap.get(ErrorType.UploadMultiple);

interface FileDropAreaProps {
  disabled?: boolean;
  onFileSelected?: (file: File) => void;
}

export const FileDropArea: React.FC<FileDropAreaProps> = ({
  disabled = false,
  onFileSelected = () => {},
}) => {
  const dispatch = useAppDispatch();
  const onDrop = async (acceptedFiles: File[]) => {
    // Do something with the files
    trackUploadFile();
    if (acceptedFiles.length === 1) {
      const selectedFile = acceptedFiles[0];
      if (selectedFile.name.includes(".xlsx") || selectedFile.name.includes(".xls")) {
        datadogLogs.logger.info("[File Drop Area] File Type Correct", {
          name: "File Type correct: XLSX",
          details: selectedFile.name,
        });
        onFileSelected(selectedFile);
      } else {
        datadogLogs.logger.error("[File Drop Area] File Type error", {
          name: "File Type error: Not XLSX",
          details: selectedFile.name,
        });
        dispatch(
          showError({
            critical: false,
            title: invalidExtError?.title,
            message: invalidExtError?.message,
          }),
        );
      }
    } else {
      datadogLogs.logger.error("[File Drop Area] File error", {
        name: "File error: Invalid file",
        details: acceptedFiles,
      });
      dispatch(
        showError({
          critical: false,
          title: uploadMultipleError?.title,
          message: uploadMultipleError?.message,
        }),
      );
    }
  };
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    disabled: disabled,
    noClick: disabled,
  });

  return (
    <div
      className={clsx("rounded bg-white mt-20 cursor-pointer mx-auto text-center", styles.dropArea)}
    >
      <div className="py-36" {...getRootProps()}>
        <SvgIcon
          name="cloud"
          attributes={{
            width: "62px",
            height: "44px",
            viewBox: "-0.6 1.65 27.5 16",
            fill: IconColors.PRIMARY,
          }}
        />
        <p className="text-dark font-size-16 font-weight-bold m-0 mt-20">
          Drag your file here or click to upload
        </p>
        <p className="text-dark font-size-12 font-weight-bold m-0 mt-12">
          Accepted File Format(s): .xlsx
        </p>
        <input {...getInputProps()} />
      </div>
    </div>
  );
};
