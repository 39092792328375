import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { SelectedItem } from "features/Quote/QuoteResults/utils";
import { ApiUrl } from "./Constants";

interface MuleTokenResult {
  access_token: string;
  scope: string;
  token_type: string;
  expires_in: number;
}
interface MuleResponse<T> {
  message: {
    messageType: string;
    data: T;
  };
}
interface NestingServiceResult extends MuleResponse<{ id: string }> {}

interface NestingServiceArgs {
  token: string;
  body: FormData;
}

interface CustomerSelectionArgs {
  uid: string;
  body: SelectedItem[];
}

interface CustomerLocaleIds {
  soldTo: string;
  shipTo: string;
}

export interface SoldToDetail {
  salesOrg: string;
  distChannel: string;
  shippingCondition: string;
  incoTerms: string;
  currencyType: string;
  paymentTerms: string;
}

export interface SAPAuxProfile extends CustomerLocaleIds, SoldToDetail {}

interface UploadResult {
  uid: string;
  quoteId: number;
}

interface ApiResult<T> {
  data: T;
}

export interface ListPrice {
  uom: string;
  currency: string;
  listPrice: number;
  listPriceUOM: string;
  lengthExtra: number;
  lengthExtraUOM: string;
  gradeExtra: number;
  gradeExtraUOM: string;
  sku: string;
  shipToCountry: string;
  error: string;
}
export interface FreightPrice {
  SHAPE: string;
  SUBFAMILY: string;
  FREIGHT: string;
  TRMODE: string;
  PRICEGROUP: string;
  FROMDATE: string;
  SIZE: string;
  TODATE: string;
  PLANT: string;
  CURRENCY: string;
  STATE: string;
  TRZONE: string;
  SHIPTOCITY: string;
}

export interface TotalPrice {
  item_uid: string;
  sku: string;
  pricingCost: string;
  pricingDescription: string;
}

const FormHeader = { "Content-Type": "multipart/form-data" };
const MuleAuthHeaders = {
  "grant-type": "client_credentials",
  scope: "READ",
  "client-secret": "0446a78Bdf6C41B2838453C654c2E633",
  "client-id": "07683497ea3540aca2371516e51208e0",
};

// once this is integrated completely RTK Query will replace
// all the manual API fetching that we are currently doing
export const QuickQuoteApi = createApi({
  reducerPath: "quickQuoteApi",
  baseQuery: fetchBaseQuery({ baseUrl: `${ApiUrl}/` }),
  endpoints: (build) => ({
    saveNestedFile: build.mutation<UploadResult, FormData>({
      query: (body) => ({
        url: "file/upload",
        method: "POST",
        body,
      }),
      transformResponse: (response: ApiResult<UploadResult>) => response.data,
    }),
    saveUncombinedFile: build.mutation<UploadResult, FormData>({
      query: (body) => ({
        url: "file/upload/unnested",
        method: "POST",
        body,
      }),
      transformResponse: (response: ApiResult<UploadResult>) => response.data,
    }),
    saveQuotePrferences: build.mutation<any, FormData>({
      query: (body) => ({
        url: "file/request_date",
        headers: FormHeader,
        method: "POST",
        body,
      }),
    }),
    getQuoteResults: build.query<any, string>({
      query: (uid) => `file/item_options/${uid}`,
    }),
    getParsedItemList: build.query<any, string>({
      query: (uid) => `file/items/${uid}`,
    }),
    saveCustomerSelections: build.mutation<any, CustomerSelectionArgs>({
      query: (args) => ({
        url: `quote/${args.uid}/selections`,
        method: "POST",
        body: args.body,
      }),
    }),
    getMuleSoftToken: build.query<string, void>({
      query: () => ({
        url: "mule/token",
        headers: MuleAuthHeaders,
      }),
      transformResponse: (response: MuleTokenResult) => response.access_token,
    }),
    sendFileToNestingService: build.mutation<string, NestingServiceArgs>({
      query: (args) => ({
        url: "mule/nesting",
        method: "POST",
        headers: { "access-token": args.token },
        body: args.body,
      }),
      transformResponse: (response: NestingServiceResult) => response.message.data.id,
    }),
    getNestedResults: build.query<any, any>({
      query: (args) => ({
        url: "mule/nesting",
        headers: { "access-token": args.token },
        params: args.params,
      }),
    }),
    getSAPAuxilaryData: build.query<SoldToDetail, CustomerLocaleIds>({
      query: (args) => ({
        url: "customer/sold_to",
        params: args,
      }),
      transformResponse: (response: ApiResult<SoldToDetail>) => response.data,
    }),
    getTotalPrice: build.query<
      TotalPrice[],
      {
        item_uid: string;
        plant: string;
        salesOrg: string;
        shippingCondition: string;
        city: string;
        state: string;
        token: string;
        sku: string;
      }
    >({
      query: (args) => ({
        url: "mule/pricing/",
        method: "POST",
        mode: "cors",
        credentials: "same-origin",
        body: [
          {
            item_uid: args.item_uid,
            salesOrg: args.salesOrg,
            plant: args.plant,
            shippingCondition: args.shippingCondition,
            city: args.city,
            state: args.state,
            sku: args.sku,
          },
        ],
        headers: {
          "access-control-allow-headers": "*",
          "access-token": args.token,
        },
        validateStatus: (response) => response.status !== 207,
      }),
      transformResponse: (response: ApiResult<TotalPrice[]>) => response.data,
    }),
  }),
});

export const {
  useGetSAPAuxilaryDataQuery,
  useGetTotalPriceQuery,
  useGetMuleSoftTokenQuery,
  useLazyGetMuleSoftTokenQuery,
  useSendFileToNestingServiceMutation,
  useSaveUncombinedFileMutation,
  useSaveNestedFileMutation,
} = QuickQuoteApi;
