import React from "react";

type TabPanelProps = {
  selectedTabIndex: number;
  index?: number;
};

export const TabPanel: React.FunctionComponent<TabPanelProps> = ({
  selectedTabIndex,
  index,
  children,
}) => {
  if (selectedTabIndex === index) {
    return <div>{children}</div>;
  }
  return null;
};
