import clsx from "clsx";
import { QQSection, RadioButton, Button, Label, ToolTip } from "common/components";
import { FileType } from "./types";
import React, { useState } from "react";
import styles from "./FileTypeForm.module.scss";

interface FileTypeFormProps {
  type: FileType | null;
  onSubmit: (fileType: FileType) => void;
}

const toolTipContinue = "Continue to Upload your File";
const toolTipDisabled = "Choose Material Buy list or Nested file";

export const FileTypeForm: React.FC<FileTypeFormProps> = ({ type, onSubmit }) => {
  const [canContinue, setCanContinue] = useState<boolean>(() => (type !== null ? true : false));
  const [fileType, setFileType] = useState<FileType | null>(type);
  const [toolTipText, setToolTipText] = useState<string>(toolTipDisabled);

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFileType(parseInt(event.target.value));
    setToolTipText(toolTipContinue);
    setCanContinue(true);
  };

  const onClick = () => {
    if (fileType !== null) onSubmit(fileType);
  };

  return (
    <QQSection className="pt-24 pb-16" separator={false} blue bottomSection>
      <form>
        <div className={clsx(styles.formContainer, "mx-auto")}>
          <div className="d-flex justify-content-center">
            <div className={clsx(styles.fileOption1, "mr-44")}>
              <div className="form-check form-check-inline">
                <RadioButton
                  id="nested"
                  name="type"
                  value={FileType.Nested}
                  checked={fileType === FileType.Nested}
                  onChange={onChange}
                  tabIndex={0}
                  large
                  className="mr-10"
                />
                <Label htmlFor="nested" className="font-size-16 text-nowrap">
                  Material Buy List
                </Label>
              </div>
              <div className="font-size-14 m-0 mt-12 text-light">
                <p className="m-0">
                  <strong>Use lengths as specified in file.</strong>
                </p>
                <p className="m-0">
                  The tool will check availability from rollings and multiple stock options and
                  generate a quote that best meets your needs.
                </p>
              </div>
            </div>
            <div className={clsx(styles.fileOption2, "mr-40")}>
              <div className="form-check form-check-inline">
                <RadioButton
                  id="unnested"
                  name="type"
                  value={FileType.Unnested}
                  checked={fileType === FileType.Unnested}
                  onChange={onChange}
                  tabIndex={0}
                  large
                  className="mr-10"
                />
                <Label htmlFor="unnested" className="font-size-16 text-nowrap">
                  Uncombined Material List
                </Label>
              </div>
              <div className="font-size-14 m-0 mt-12 text-light">
                <p className="m-0">
                  <strong>Combine lengths to minimize loss</strong>
                </p>
                <p className="m-0">
                  The tool will combine similar material components and convert to mill capable
                  lengths, check availability from rollings and multiple stock options and generate
                  a quote that best meets your needs.
                </p>
              </div>
            </div>
            <div className="align-center text-center">
              <ToolTip placement="top" popOverText={toolTipText} enableTip={!canContinue}>
                <span className="d-inline-block">
                  <Button
                    disabled={!canContinue}
                    className={clsx({ [styles.pointerEvents]: !canContinue })}
                    onClick={onClick}
                    type="button"
                  >
                    Continue
                  </Button>
                </span>
              </ToolTip>
            </div>
          </div>
        </div>
      </form>
    </QQSection>
  );
};
