import React from "react";
import clsx from "clsx";
import styles from "./TextInput.module.scss";

interface TextInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  ref?: React.RefObject<HTMLInputElement>;
}

export const TextInput = React.forwardRef<HTMLInputElement, TextInputProps>(
  ({ className, type, ...rest }, ref) => {
    return (
      <input
        className={clsx("form-control font-size-14 text-dark", styles.container, className)}
        ref={ref}
        type="text"
        {...rest}
      />
    );
  },
);
