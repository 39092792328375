import clsx from "clsx";
import { QQSection, SvgIcon } from "common/components";
import React from "react";
import downloadTemplate from "./assets/01_downloading_template.png";
import formattingFile from "./assets/02_formatting_file.png";
import uploadingFile from "./assets/03_uploading_file.png";
import nestedOrUnnested from "./assets/04_nested_unnested.png";
import unnestedPrefs from "./assets/05_unnested_prefs.png";
import shipToRequestedDate from "./assets/06_shipto_requested_date.png";
import understandingResults from "./assets/07_understanding_results.png";
import cutLists from "./assets/08_cut_lists.png";
import summaryDownload from "./assets/09_summary_download.png";
import placingOrder from "./assets/10_placing_order.png";
import styles from "./HowToPage.module.scss";
import videomp4 from "./assets/QQTDemowithNestingandCC.mp4";
import videowebm from "./assets/QQT Demo with Nesting and CC.webmhd.webm";
import videoOgv from "./assets/QQT Demo with Nesting and CC.oggtheora.ogv";
import videoPoster from "./assets/00_video_postcard.png";
import { HowToSection } from "./HowToSection";

export const HowToPage: React.FunctionComponent = () => {
  return (
    <QQSection blue separator={false} className={clsx(styles.container, "pt-100 pb-24")}>
      <div className={clsx(styles.col, "mx-auto")}>
        <video poster={videoPoster} controls>
          <source src={videomp4} type="video/mp4" />
          <source src={videowebm} type="video/webm" />
          <source src={videoOgv} type="video/ogg" />
          <p>Your browser doesn't support viewing this video.</p>
        </video>
        <h4 className="mt-104 font-size-58 font-weight-normal text-secondary">Getting Started</h4>
        <p className="font-size-20">How to import your ABM file</p>
        <ul className="list-unstyled ml-8 font-size-14">
          <li>
            <a href="#download">Downloading the template</a>
          </li>
          <li>
            <a href="#format">Formatting your file</a>
          </li>
          <li>
            <a href="#upload">Uploading your file</a>
          </li>
          <li>
            <a href="#nesting">Choose Material Buy list or Uncombined Material List</a>
          </li>
          <li>
            <a href="#prefs">Uncombined Preferences</a>
          </li>
          <li>
            <a href="#shipping">Setting your Ship To and Requested Date</a>
          </li>
          <li>
            <a href="#results">Understanding your results</a>
          </li>
          <li>
            <a href="#cuts">Cut Lists (Uncombined Only)</a>
          </li>
          <li>
            <a href="#summary">Summary and quote download</a>
          </li>
          <li>
            <a href="#order">Placing your order</a>
          </li>
        </ul>
        <div className={clsx(styles.emailCard, "card mt-24 p-6 border-0 rounded w-50")}>
          <p className="font-size-14 mb-0 px-7 py-4">Need Help?</p>
          <div className="d-flex px-7 py-4 align-items-center">
            <SvgIcon name="email" className="mr-4" />
            <a
              href="mailto: quickquotetool_support@gerdau.com?subject=Quick Quote Help"
              className="text-decoration-none"
            >
              quickquotetool_support@gerdau.com
            </a>
          </div>
        </div>
        <HowToSection
          headingText="Downloading the template"
          anchorId="download"
          imageSrc={downloadTemplate}
          altText="Download Template"
        />
        <HowToSection
          headingText="Formatting your file"
          anchorId="format"
          imageSrc={formattingFile}
          altText="Formatting your file"
        >
          <p className="font-size-16">
            The template has a simplified copy-n-paste format. Copy from your data into the template
          </p>
        </HowToSection>
        <HowToSection
          headingText="Uploading your file"
          anchorId="upload"
          imageSrc={uploadingFile}
          altText="Uploading your file"
        />
        <HowToSection
          headingText="Choose Material Buy list or Uncombined Material List"
          anchorId="nesting"
          imageSrc={nestedOrUnnested}
          altText="Material Buy list or Uncombined Material List File"
        />
        <HowToSection
          headingText="Uncombined preferences"
          anchorId="prefs"
          imageSrc={unnestedPrefs}
          altText="Uncombined preferences"
        ></HowToSection>
        <HowToSection
          headingText="Setting your Ship To and Requested Date"
          anchorId="shipping"
          imageSrc={shipToRequestedDate}
          altText="Setting your Ship To and Requested Date"
        />
        <HowToSection
          headingText="Understanding your results"
          anchorId="results"
          imageSrc={understandingResults}
          altText="Understanding your results"
        />
        <HowToSection
          headingText="Cut lists (Uncombined Only)"
          anchorId="cuts"
          imageSrc={cutLists}
          altText="Cut lists (Uncombined Only)"
        ></HowToSection>
        <HowToSection
          headingText="Summary and quote download"
          anchorId="summary"
          imageSrc={summaryDownload}
          altText="Summary and quote download"
        />
        <HowToSection
          headingText="Placing your order"
          anchorId="order"
          imageSrc={placingOrder}
          altText="Placing your order"
        />
      </div>
    </QQSection>
  );
};
