import clsx from "clsx";
import { QQSection } from "common/components";
import React from "react";
import { ProgressBar } from "react-bootstrap";
import { calculateDropPercent, combineCutListPieces, formatItemDescription, Item } from "../utils";
import { CutListTableRow } from "./CutListRow";
import styles from "./CutListTable.module.scss";

interface CutListTableProps {
  item: Item | null;
  quantitySelected?: number;
}

export const CutListTable: React.FunctionComponent<CutListTableProps> = ({
  item,
  quantitySelected = 0,
}) => {
  const dropPercent = () => calculateDropPercent(item).toFixed(2);
  return item ? (
    <div className={styles.container}>
      <QQSection blue separator={false} className="px-24 pt-18 pb-12">
        <h6 className="mb-3 text-secondary font-size-16 font-weight-bold">
          {formatItemDescription(item)}
          <span className={clsx(styles.openSans, "d-inline-block ml-12 font-weight-normal")}>
            <span className="text-light font-size-12">Overall Drop</span>
            <span
              className={clsx(
                styles.dropBox,
                "d-inline-block ml-6 p-4 bg-warning font-size-10 text-light",
              )}
            >
              {`${dropPercent()}%`}
            </span>
          </span>
        </h6>
        <p className="m-0 p-0 font-size-12">
          <span className="mr-3 text-inactive">(</span>
          {`${item.pieces} Pieces Requested / ${quantitySelected} Pieces Selected`}
          <span className="ml-3 text-inactive">)</span>
        </p>
      </QQSection>
      <QQSection className="p-24" white bottomSection>
        <table className="table table-bordered">
          <thead>
            <tr className="bg-secondary text-white">
              <th scope="col">Pieces</th>
              <th scope="col">Cuts</th>
              <th></th>
              <th scope="col">Length</th>
              <th scope="col">Drop</th>
              <th scope="col">Mill Mark</th>
            </tr>
          </thead>
          <tbody className="font-size-12">
            {item.cut_list &&
              combineCutListPieces(item.cut_list).map((option) =>
                option.pieces.map((piece: { length: number; quantity: number }, index: number) => (
                  <CutListTableRow
                    key={index}
                    length={piece.length}
                    quantity={piece.quantity}
                    wastePercent={option.waste_pct}
                    pieceCount={option.piecesCount}
                    rowSpan={option.rowSpan}
                    index={index}
                    option={option}
                    millMark={option.millMark}
                  ></CutListTableRow>
                )),
              )}
          </tbody>
        </table>
      </QQSection>
      <QQSection
        blue
        separator={false}
        className="px-24 pt-18 pb-12"
        hidden={quantitySelected <= item.pieces}
      >
        <h6 className="mb-3 text-secondary font-size-16 font-weight-bold">Additional Pieces</h6>
        <p className="text-light font-size-12">
          (Standard length product, must order in bundle quantities)
        </p>
        <table className="table">
          <tbody className="font-size-12">
            <tr>
              <td colSpan={5} className="bt-0">
                <ProgressBar
                  className={clsx(styles.progressBarSpace)}
                  variant={"success"}
                  now={100}
                  key={1}
                ></ProgressBar>
              </td>
            </tr>
            <tr>
              <td>{`${quantitySelected - item.pieces} PC`}</td>
            </tr>
          </tbody>
        </table>
      </QQSection>
    </div>
  ) : null;
};
