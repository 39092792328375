import React from "react";
import { SvgIcon, IconColors } from "common/components";
import styles from "./ResultsHeaderToggle.module.scss";
import clsx from "clsx";

type ResultsHeaderToggleProps = {
  toggle: boolean;
  resultCount: number;
  text: string;
  onClick: () => void;
};

export const ResultsHeaderToggle: React.FunctionComponent<ResultsHeaderToggleProps> = ({
  text,
  resultCount,
  toggle = false,
  onClick,
}) => {
  const svgStyle = "mr-12 align-baseline";
  return (
    <h4
      className={clsx(
        "font-size-24 font-weight-bold text-primary m-0",
        { "mb-16": resultCount > 0 },
        styles.container,
      )}
    >
      <span onClick={() => onClick()}>
        {toggle === false ? (
          <SvgIcon
            name="plus"
            attributes={{
              width: "16px",
              height: "16px",
              viewBox: "7 7 16 16",
              fill: IconColors.PRIMARY,
            }}
            className={svgStyle}
          />
        ) : (
          <SvgIcon
            name="minus"
            attributes={{
              width: "16px",
              height: "16px",
              viewBox: "7 7 16 16",
              fill: IconColors.PRIMARY,
            }}
            className={svgStyle}
          />
        )}
      </span>
      {text} <span className="font-size-16 text-light">({resultCount})</span>
    </h4>
  );
};
