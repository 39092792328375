import { ApiUrl } from "common/services";
export const QuoteSelectionsUri = (uid: string) => `${ApiUrl}/quote/${uid}/selections`;
export const MuleNestingApiUri = `${ApiUrl}/mule/nesting`;
export const NestedQuoteUri = (uid: string) => `${ApiUrl}/quote/nested/${uid}`;
export const RequestDateUri = `${ApiUrl}/file/request_date`;
export const ItemOptionsUri = (uid: string) => `${ApiUrl}/file/item_options/${uid}`;
export const ContactSalesRep = "Contact Sales Representative";
export const MuleAuthApiUrl = `${ApiUrl}/mule/token`;
export const MuleAuthHeaders = {
  "grant-type": "client_credentials",
  scope: "READ",
  "client-secret": "0446a78Bdf6C41B2838453C654c2E633",
  "client-id": "07683497ea3540aca2371516e51208e0",
};
export const UnnestedUploadUri = `${ApiUrl}/file/upload/unnested`;
export const UploadFileUri = `${ApiUrl}/file/upload`;
export const TemplateDownloadUrl = `${ApiUrl}/file/download_gerdau_template`;
