import { apiDateToUsDate } from "features/Quote/QuoteResults/utils";
export const trackCustomDimensions = (customer) => {
  try {
    window.dataLayer?.push({
      userId: customer.uid,
      userType: customer.usertype,
      soldTo: "",
      salesRep: "",
    });
  } catch (e) {
    console.error("GA Code Error");
  }
};
export const trackDownloadTemplate = () => {
  try {
    window.dataLayer?.push({
      event: "qqt",
      eventCategory: "upload - file",
      eventAction: "download",
      eventLabel: "template",
    });
  } catch (e) {
    console.error("GA Code Error");
  }
};

export const trackUploadFile = () => {
  try {
    window.dataLayer?.push({
      event: "qqt",
      eventCategory: "upload - file",
      eventAction: "browse",
      eventLabel: "open",
    });
  } catch (e) {
    console.error("GA Code Error");
  }
};
export const trackUploadFileContinue = (fileName, filetype) => {
  try {
    const type = filetype === 0 ? "Material Buy List" : "Uncombined Material List";
    window.dataLayer?.push({
      event: "qqt",
      eventCategory: "upload - file",
      eventAction: "browse",
      eventLabel: fileName + "|" + type,
    });
  } catch (e) {
    console.error("GA Code Error");
  }
};
export const trackGenerateQuote = (data) => {
  try {
    window.dataLayer?.push({
      event: "qqt",
      eventCategory: "quote - submit",
      eventAction: "submit",
      eventLabel: data.transportation + "|" + data.shipTo + "|" + data.requestDate,
    });
  } catch (e) {
    console.error("GA Code Error");
  }
};
export const trackQuoteFilter = (filterBy, filterLabel) => {
  try {
    window.dataLayer?.push({
      event: "qqt",
      eventCategory: "quote - needs review",
      eventAction: "filterBy",
      eventLabel: filterBy + "|" + filterLabel,
    });
  } catch (e) {
    console.error("GA Code Error");
  }
};

export const trackQuoteItemSelection = (isSelected, option) => {
  try {
    const action = isSelected ? "Select" : "Unselect";
    window.dataLayer?.push({
      event: "qqt",
      eventCategory: "quote - needs review",
      eventAction: "modify-" + action,
      eventLabel:
        option.description +
        "|" +
        option.plantName +
        "|" +
        option.orderQuantity +
        " PC|" +
        option.standard_piece_count +
        " PC/BDL|" +
        apiDateToUsDate(option.date_available),
    });
  } catch (e) {
    console.error("GA Code Error");
  }
};

export const trackCreateQuote = () => {
  try {
    window.dataLayer?.push({
      event: "qqt",
      eventCategory: "quote - new",
      eventAction: "click",
      eventLabel: "Create another quote",
    });
  } catch (e) {
    console.error("GA Code Error");
  }
};
export const trackDownloadQuote = () => {
  try {
    window.dataLayer?.push({
      event: "qqt",
      eventCategory: "Download file",
      eventAction: "click",
      eventLabel: "Download file into excel",
    });
  } catch (e) {
    console.error("GA Code Error");
  }
};
export const trackFinishQuote = () => {
  try {
    window.dataLayer?.push({
      event: "qqt",
      eventCategory: "quote - submit",
      eventAction: "submit",
      eventLabel: "Finish quote",
    });
  } catch (e) {
    console.error("GA Code Error");
  }
};
