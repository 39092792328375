import { schema } from "normalizr";
import { MillMap } from "common/services";
import { calculateBundleWeight } from "./helpers";

const plantOptionsEntity = new schema.Entity(
  "plantOptions",
  {},
  {
    idAttribute: "uid",
    processStrategy: (value, parent) => ({
      ...value,
      parentItemId: parent.parentItemId,
      standardBundleWeight: calculateBundleWeight(
        value.fulfilled_length,
        value.size,
        value.standard_piece_count,
      ),
      plantName: parent.plantDetail.name,
      pieces: parent.pieces,
    }),
  },
);
const itemPlantsEntity = new schema.Entity(
  "itemPlants",
  { options: [plantOptionsEntity] },
  {
    idAttribute: (value, parent) => `${Object.keys(value)[0]}:${parent.item_uid}`,
    processStrategy: (value, parent) => {
      const plantId = Object.keys(value)[0];
      return {
        distance: value[plantId].distance,
        parentItemId: parent.item_uid,
        plantId,
        plantDetail: MillMap.get(+plantId),
        pieces: parent.pieces,
        options: value[plantId].options,
      };
    },
  },
);
export const itemsEntity = new schema.Entity(
  "items",
  { options: [itemPlantsEntity] },
  { idAttribute: "item_uid" },
);
