import clsx from "clsx";
import { Label, TextInput } from "common/components";
import React, { useState } from "react";
import Calendar from "react-calendar";
import { UseFormRegister, UseFormSetValue } from "react-hook-form";
import { FormFields, formatDate, toDate } from "../utils";
import "./CalendarInput.scss";

interface CalendarInputProps {
  register: UseFormRegister<FormFields>;
  setValue: UseFormSetValue<FormFields>;
  requestDate: string;
  labelText: string;
}

export const CalendarInput: React.FunctionComponent<CalendarInputProps> = ({
  register,
  setValue,
  requestDate,
  labelText,
}) => {
  const [calendarComponentHasFocus, setCalendarComponentHasFocus] = useState<boolean>(false);
  const [timeoutId, setTimeoutId] = useState<number | undefined>(undefined);
  const calendarOnBlur = () => {
    if (calendarComponentHasFocus) {
      setTimeoutId(
        window.setTimeout(() => {
          setCalendarComponentHasFocus(false);
        }, 500),
      );
    }
  };
  return (
    <div
      onBlur={calendarOnBlur}
      onFocus={() => {
        window.clearTimeout(timeoutId);
        if (!calendarComponentHasFocus) {
          setCalendarComponentHasFocus(true);
        }
      }}
    >
      <Label htmlFor="requested-date" className="mb-4">
        {labelText}
      </Label>
      <TextInput
        id="requested-date"
        placeholder="Requested Delivery Date"
        autoComplete="off"
        value={requestDate}
        readOnly
        {...register("requestDate", { required: true })}
      />

      <div
        className={clsx("calendar-container", {
          "d-none": !calendarComponentHasFocus,
          "d-block": calendarComponentHasFocus,
        })}
      >
        <Calendar
          onChange={(value: any) => {
            if (value instanceof Date) {
              setValue("requestDate", formatDate(value));
              calendarOnBlur();
            }
          }}
          value={toDate(requestDate)}
          view="month"
          next2Label={null}
          prev2Label={null}
          minDate={new Date()}
          minDetail="month"
          formatShortWeekday={(locale, date) =>
            date.toLocaleString(locale, { weekday: "short" }).charAt(0)
          }
          onClickMonth={() => {}}
        />
      </div>
    </div>
  );
};
