import clsx from "clsx";
import React from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { Placement } from "react-bootstrap/esm/Overlay";
import { OverlayDelay, OverlayTriggerType } from "react-bootstrap/esm/OverlayTrigger";
import styles from "./ToolTip.module.scss";

interface ToolTipProps {
  delay?: OverlayDelay;
  trigger?: OverlayTriggerType | OverlayTriggerType[];
  placement?: Placement;
  popOverTitle?: string;
  popOverText?: string;
  popOverClassName?: string;
  show?: boolean;
  enableTip?: boolean;
}

export const ToolTip: React.FC<ToolTipProps> = ({
  children,
  popOverText,
  popOverClassName,
  enableTip,
  ...rest
}) => {
  const popover = (
    <Popover
      id="popover"
      className={clsx(styles.container, "bg-dark font-size-14 text-white", popOverClassName)}
    >
      <Popover.Content className="px-8 py-4 font-size-14 text-white">{popOverText}</Popover.Content>
    </Popover>
  );

  if (enableTip) {
    return (
      <OverlayTrigger overlay={popover} {...rest}>
        {children as React.ReactElement}
      </OverlayTrigger>
    );
  }
  return (
    <OverlayTrigger overlay={popover} show={false} {...rest}>
      {children as React.ReactElement}
    </OverlayTrigger>
  );
};
