import clsx from "clsx";
import { Label, TextInput, Notification, NotifyType, SvgIcon } from "common/components";
import { useInput } from "./useInput";
import React from "react";
import styles from "./SearchForm.module.scss";

interface SearchFormProps {
  onCleared?: () => void;
  onSubmit?: (value: string) => void;
  hasError?: boolean;
  errorMessage?: string;
  searchRef?: React.RefObject<HTMLInputElement>;
}

const EnterKey = "Enter";

export const SearchForm: React.FC<SearchFormProps> = ({
  onCleared = () => {},
  onSubmit = () => {},
  hasError = false,
  errorMessage = "",
  searchRef,
}) => {
  const search = useInput("");
  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    search.onChange(event);
    if (event.target.value === "") onCleared();
  };
  const onKeyUp = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === EnterKey) {
      onSubmit(search.value);
    }
  };
  const onClick = () => {
    search.reset();
    onCleared();
  };

  return (
    <div className={styles.searchForm}>
      <Label>Enter QQT ID</Label>
      <div className={clsx("d-flex mb-5 position-relative", styles.search)}>
        <TextInput
          value={search.value}
          className={clsx("pr-20", hasError && styles.inputError)}
          type="text"
          name="id"
          onChange={onChange}
          ref={searchRef}
          onKeyUp={onKeyUp}
        />
        {search.value !== "" && (
          <SvgIcon
            name="clear"
            onClick={onClick}
            className={clsx(
              "align-self-center position-relative",
              styles.closeIcon,
              hasError && styles.error,
            )}
          />
        )}
      </div>
      {hasError === true && (
        <div className={clsx(styles.notFoundError, "font-size-12")}>
          <Notification type={NotifyType.Error} text={errorMessage} />
        </div>
      )}
    </div>
  );
};
