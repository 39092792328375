import {
  EgerdauCustomer,
  useLazyGetMuleSoftTokenQuery,
  useSaveNestedFileMutation,
  useSaveUncombinedFileMutation,
  useSendFileToNestingServiceMutation,
} from "common/services";
import { FileType, FileUpload } from "features/Quote/Upload";
import { useEffect, useState } from "react";

interface FileUploadResult {
  uid: string | null;
  quoteId: number | null;
  nestingUid: string | null;
  token: string | null;
}
const hasFileChanged = (current: FileUpload, prev: FileUpload) =>
  current.fileType !== prev.fileType || current.file?.name !== prev.file?.name;
const getInitResult = (): FileUploadResult => ({
  quoteId: null,
  uid: null,
  nestingUid: null,
  token: null,
});

export const useSaveUploadedFile = (file: FileUpload, customer: EgerdauCustomer | null) => {
  const [cachedFile, setcachedFile] = useState<FileUpload>(file);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<any>(null);
  const [result, setResult] = useState<FileUploadResult>(getInitResult());
  const [triggerUpload, setTriggerUpload] = useState<boolean>(false);
  const [getMSToken] = useLazyGetMuleSoftTokenQuery();
  const [postFileNestingService] = useSendFileToNestingServiceMutation();
  const [postUncombinedQQTApi] = useSaveUncombinedFileMutation();
  const [postNestedQQTApi] = useSaveNestedFileMutation();
  const resetError = () => {
    setError(null);
    setcachedFile({ file: null, fileType: null });
  };
  const resetLastResult = () => setResult(getInitResult());
  const uploadComplete = (result: FileUploadResult) => {
    setResult(result);
    setError(null);
  };

  if (file.file && hasFileChanged(file, cachedFile) && !error && !isLoading) {
    setcachedFile(file);
    setTriggerUpload(true);
  }

  useEffect(() => {
    const postUncombinedFile = async () => {
      const result = getInitResult();
      const { file: localFile } = file;
      try {
        if (localFile) {
          setIsLoading(true);
          result.token = await getMSToken(undefined, true).unwrap();
          const body = new FormData();
          body.append("file", localFile);
          result.nestingUid = await postFileNestingService({ body, token: result.token }).unwrap();
          if (result.nestingUid) {
            const formData = new FormData();
            formData.append("upload_file", localFile);
            formData.append("user_info", JSON.stringify(customer));
            const uploadResult = await postUncombinedQQTApi(formData).unwrap();
            result.uid = uploadResult.uid;
            result.quoteId = uploadResult.quoteId;
          }
          uploadComplete(result);
        }
      } catch (error) {
        setError(error);
      } finally {
        setIsLoading(false);
        setTriggerUpload(false);
      }
    };
    if (triggerUpload && file.fileType === FileType.Unnested) {
      postUncombinedFile();
    }
  }, [customer, file, getMSToken, postFileNestingService, postUncombinedQQTApi, triggerUpload]);

  useEffect(() => {
    const postNestedFile = async () => {
      const result: FileUploadResult = getInitResult();
      const { file: localFile } = file;
      try {
        if (localFile) {
          setIsLoading(true);
          const formData = new FormData();
          formData.append("upload_file", localFile);
          formData.append("user_info", JSON.stringify(customer));
          const uploadResult = await postNestedQQTApi(formData).unwrap();
          result.uid = uploadResult.uid;
          result.quoteId = uploadResult.quoteId;
          uploadComplete(result);
        }
      } catch (error) {
        setError(error);
      } finally {
        setIsLoading(false);
        setTriggerUpload(false);
      }
    };
    if (triggerUpload && file.fileType === FileType.Nested) {
      postNestedFile();
    }
  }, [customer, file, postNestedQQTApi, triggerUpload]);
  return { ...result, error, isLoading, resetError, resetLastResult };
};
