import clsx from "clsx";
import { TextInput } from "common/components";
import React from "react";
import { MillMap, LocaleString } from "common/services";
import { MillWeights } from "../utils";
import styles from "./MillWeightFooter.module.scss";

type MillWeightFooterProps = {
  millWeights: MillWeights;
};

const getDisplayWeight = (weight: number) => Math.trunc(weight).toLocaleString(LocaleString);

export const MillWeightFooter: React.FunctionComponent<MillWeightFooterProps> = ({
  millWeights,
}) => {
  return (
    <div
      className={clsx(
        "d-flex align-items-center bg-secondary font-size-16 w-100 justify-content-around",
        styles.container,
      )}
    >
      {Array.from(MillMap, ([millId]) => millId).map((millId) => (
        <div key={millId} className="d-flex align-items-center">
          <p className="m-0 mr-10 text-uppercase font-weight-bold">{MillMap.get(millId)?.name}</p>
          <TextInput
            type="text"
            readOnly={true}
            value={`${getDisplayWeight(millWeights[millId])} LB`}
            className="font-size-12 bg-info"
          />
        </div>
      ))}
    </div>
  );
};
