import React from "react";
import ProgressBar from "react-bootstrap/ProgressBar";
import styles from "./CutListTable.module.scss";
import clsx from "clsx";

export type CutListProgressBarProps = {
  quantity: number;
  wastePercent: number;
  variant: string;
};

export const CutListProgressBar: React.FunctionComponent<CutListProgressBarProps> = ({
  quantity,
  wastePercent,
  variant,
}) => {
  const percentCalc = (100 - wastePercent) / quantity;
  let elements = [];

  for (let i = 0; i < quantity; i++) {
    elements.push(
      <ProgressBar
        className={clsx(styles.progressBarSpace)}
        variant={variant}
        now={percentCalc}
        key={i}
        isChild={true}
      />,
    );
  }
  return <>{elements}</>;
};
