import React, { ReactNode } from "react";

type TabProps = {
  isActive?: boolean;
  isInactive?: boolean;
  isComplete?: boolean;
  onClick?: () => void;
  children: (isActive: boolean, isComplete: boolean, isInactive: boolean) => ReactNode;
};

export const Tab: React.FunctionComponent<TabProps> = ({
  onClick = () => {},
  isActive = false,
  isComplete = false,
  isInactive = true,
  ...props
}) => {
  return (
    <li className="flex-fill text-center cursor-pointer" onClick={() => onClick()}>
      {props.children(isActive, isComplete, isInactive)}
    </li>
  );
};
