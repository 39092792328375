import clsx from "clsx";
import { Button, Label } from "common/components";
import React from "react";
import styles from "./SearchButtonGroup.module.scss";

interface SearchButtonGroupProps {
  labels: string[];
  heading: string;
  selectedLabel: string;
  onButtonClick?: (index: number, label: string) => void;
}

export const SearchButtonGroup: React.FC<SearchButtonGroupProps> = ({
  labels,
  heading,
  selectedLabel,
  onButtonClick = () => {},
}) => {
  const onClick = (index: number, label: string) => onButtonClick(index, label);
  const selected = (idx: number) => idx === labels.findIndex((value) => value === selectedLabel);
  return (
    <div className={styles.container}>
      <Label>{heading}</Label>
      <div className="d-flex justify-content-between">
        {labels.map((label, idx) => (
          <Button
            key={idx}
            className={clsx(styles.button, selected(idx) && styles.active)}
            onClick={() => onClick(idx, labels[idx])}
          >
            {label}
          </Button>
        ))}
      </div>
    </div>
  );
};
